/* eslint-disable  no-new */
import FormCtaTile from './form_cta/form_cta_tile';
import initItemTile from './item/item_tile';
import LinkCtaTile from './link_cta/link_cta_tile';

export default class TileArea {
  private readonly container: HTMLElement;

  private readonly tileClasses = {
    formCta: 'uf-form-cta-tile',
    item: 'uf-tile-wrapper',
    linkCta: 'uf-link-cta-tile',
  };

  public constructor(container: HTMLElement) {
    this.container = container;
  }

  private isFormCta = (tile: HTMLElement) => tile.classList.contains(this.tileClasses.formCta);

  private isLinkCta = (tile: HTMLElement) => tile.classList.contains(this.tileClasses.linkCta);

  private isItemTile = (tile: HTMLElement) => tile.classList.contains(this.tileClasses.item);

  private getTileSelectorGroup = (): string => {
    const selectors = Object.values(this.tileClasses);
    return selectors.map((selector) => `.${selector}`).join(', ');
  };

  private getTiles = () => {
    const query = this.getTileSelectorGroup();
    return [...(this.container.querySelectorAll(query) as NodeListOf<HTMLElement>)];
  };

  public initializeTile(tile: HTMLElement) {
    if (!tile || !tile.classList) {
      return;
    }

    if (this.isItemTile(tile)) {
      initItemTile(tile);
      return;
    }

    if (this.isLinkCta(tile)) {
      new LinkCtaTile(tile);
      return;
    }

    if (this.isFormCta(tile)) {
      new FormCtaTile(tile);
      return;
    }

    throw new Error(`Tile Initializer was passed an invalid element: ${tile.outerHTML}`);
  }

  /**
   * Initialize whatever we need for each tile inside this tile area.
   * These functions should be idempotent since we call them on all tiles multiple times.
   */
  public initializeTiles() {
    this.getTiles().forEach((tile) => this.initializeTile(tile));
  }
}
