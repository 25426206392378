import { isTableBreakpoint } from '../../../../common/js_helpers/dom_helpers';
import hubEvents from '../../../../common/hub_events/hub_events';

export interface LocaleSelectorElements {
  body: HTMLElement;
  parent: HTMLElement;
  openBtn: HTMLButtonElement;
  closeBtn: HTMLButtonElement;
  menu: HTMLElement;
  menuContent: HTMLElement;
  links: HTMLAnchorElement[];
}

class LocaleSelectorComponent {
  private readonly DISABLE_SCROLL_CLASS_NAME: string = 'no-scroll';

  private readonly ACTIVE_CLASS_NAME: string = 'is-active';

  private readonly HOVER_ACTIVE_CLASS_NAME: string = 'is-hovered';

  private readonly selectors = {
    closeBtn: '#uf-locale-selector-close',
    links: '.uf-locale-selector-link',
    menu: '#uf-locale-selector-dropdown',
    menuContent: '#uf-locale-selector-dropdown-content',
    openBtn: '#uf-locale-selector-open',
    parentById: 'uf-locale-selector',
  };

  private dom!: LocaleSelectorElements;

  public constructor() {
    if (this.setBindings() && this.dom.links.length) {
      this.init();
    } else {
      this.destruct();
    }
  }

  private setBindings = (): boolean => {
    const body = document.body as HTMLElement;
    const parent = document.getElementById(this.selectors.parentById) as HTMLElement;
    if (!body || !parent) {
      return false;
    }

    const openBtn = parent.querySelector(this.selectors.openBtn) as HTMLButtonElement;
    const closeBtn = parent.querySelector(this.selectors.closeBtn) as HTMLButtonElement;
    const menu = parent.querySelector(this.selectors.menu) as HTMLElement;
    const menuContent = parent.querySelector(this.selectors.menuContent) as HTMLElement;
    const links = [...parent.querySelectorAll(this.selectors.links)] as HTMLAnchorElement[];
    if (!openBtn || !closeBtn || !menu || !menuContent || !links.length) {
      return false;
    }

    this.dom = { body, closeBtn, links, menu, menuContent, openBtn, parent };

    return true;
  };

  // when Click and Mouseover compete in Desktop mode, yield to Mouseover
  private init = (): void => {
    this.dom.parent.addEventListener('mouseenter', this.desktopMouseEnter);
    this.dom.parent.addEventListener('mouseleave', this.desktopMouseLeave);

    this.dom.openBtn.addEventListener('click', this.clickOpen);
    this.dom.closeBtn.addEventListener('click', this.clickClose);
    this.dom.parent.addEventListener('keydown', this.escapeKeyClose);

    this.dom.links.forEach((anchorElement: HTMLAnchorElement) => {
      anchorElement.addEventListener('click', this.clickClose);
    });

    hubEvents.subscribe('resize', this.checkCloseByResize);
  };

  private destruct = (): void => {
    if (this.dom && this.dom.parent && this.dom.parent.parentNode) {
      this.dom.parent.parentNode.removeChild(this.dom.parent);
    }
  };

  private desktopMouseEnter = (): void => {
    if (!isTableBreakpoint()) {
      this.dom.parent.classList.add(this.HOVER_ACTIVE_CLASS_NAME);
      this.dom.openBtn.setAttribute('aria-expanded', 'true');
    }
  };

  private desktopMouseLeave = (): void => {
    if (!isTableBreakpoint() && this.isHovered()) {
      this.sanitizeOnClose();
    }
  };

  private sanitizeOnClose = (): void => {
    document.removeEventListener('focusin', this.checkCloseByFocusOutside);
    document.removeEventListener('click', this.checkCloseByClickOutside);

    this.dom.body.classList.remove(this.DISABLE_SCROLL_CLASS_NAME);
    this.dom.parent.classList.remove(this.HOVER_ACTIVE_CLASS_NAME);
    this.dom.parent.classList.remove(this.ACTIVE_CLASS_NAME);

    this.dom.openBtn.setAttribute('aria-expanded', 'false');
  };

  private clickOpen = (): void => {
    if (this.isHovered()) {
      return;
    }

    document.addEventListener('focusin', this.checkCloseByFocusOutside);
    document.addEventListener('click', this.checkCloseByClickOutside);

    this.dom.body.classList.add(this.DISABLE_SCROLL_CLASS_NAME);
    this.dom.parent.classList.add(this.ACTIVE_CLASS_NAME);

    this.dom.openBtn.setAttribute('aria-expanded', 'true');

    this.dom.links[0].focus();
  };

  private clickClose = (): void => {
    if (this.isHovered()) {
      return;
    }
    this.sanitizeOnClose();
    this.dom.openBtn.focus();
  };

  private escapeKeyClose = (event: Event): void => {
    const keyEvent = event as KeyboardEvent;
    if (keyEvent.key === 'Escape' || keyEvent.key === 'Esc') {
      this.clickClose();
      this.dom.openBtn.focus();
    }
  };

  private isHovered = (): boolean => {
    if (isTableBreakpoint()) {
      this.sanitizeOnClose();
      return false;
    }
    return this.dom.parent.classList.contains(this.HOVER_ACTIVE_CLASS_NAME);
  };

  private isActive = (): boolean => this.dom.parent.classList.contains(this.ACTIVE_CLASS_NAME);

  // close Locale Selector dropdown when tab-focus leaves the container element
  private checkCloseByFocusOutside = (event: Event): void => {
    const isFocusInDropdown = this.dom.menuContent.contains(event.target as Element);
    if (!isFocusInDropdown) {
      this.sanitizeOnClose();

      if (isTableBreakpoint()) {
        this.dom.openBtn.focus();
      }
    }
  };

  // close Locale Selector dropdown when mouse-click on the background
  private checkCloseByClickOutside = (event: Event): void => {
    const isNotLanguageSelector = !this.dom.parent.contains(event.target as Element);
    const clickedMobileMenuBackground = event.target === this.dom.menu;
    if (isNotLanguageSelector || clickedMobileMenuBackground) {
      this.sanitizeOnClose();

      if (isTableBreakpoint()) {
        this.dom.openBtn.focus();
      }
    }
  };

  private checkCloseByResize = (): void => {
    if (this.isActive() && !isTableBreakpoint()) {
      this.sanitizeOnClose();
      this.dom.openBtn.focus();
    }
  };
}

export default LocaleSelectorComponent;
