import { getCookie, setCookie } from '../../../../../common/js_helpers/dom_helpers';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetElqCustomerGUID: any;
  }
}

class EloquaService {
  private readonly FIRST_PARTY_COOKIE_NAME = 'ELOQUA';

  private readonly UTK_COOKIE_NAME = 'eloquautk';

  public constructor() {
    const isConnected = window.uberflip.eloquaConnected;

    if (isConnected) {
      this.registerVisitor();
    }
  }

  private registerVisitor = (): void => {
    if (this.updateVisitor() === null) {
      let intervalCount = 0;
      // 12 * 500ms = 6s max total wait time -- recommended by product team
      const maxIntervalCount = 12;
      const intervalTimeout = 500;

      const interval = setInterval(() => {
        intervalCount += 1;

        if (this.updateVisitor() !== null || intervalCount === maxIntervalCount) {
          clearInterval(interval);
        }
      }, intervalTimeout);
    }
  };

  private updateVisitor = (): string | null => {
    const guid = this.getVisitorGUID();

    if (guid && this.getUTKValue() !== guid) {
      this.setUTKValue(guid);
    }
    return guid;
  };

  private getVisitorGUID = (): string | null => {
    if (window.uberflip.eloquaFirstPartyCookies === true) {
      const cookieValue = getCookie(this.FIRST_PARTY_COOKIE_NAME);
      const params = (cookieValue || '').split('&');

      for (let i = 0; i < params.length; i += 1) {
        const param = params[i].split('=');
        if (param.length === 2) {
          const paramName = param[0];
          const paramValue = param[1];
          if (paramName === 'GUID') {
            return paramValue;
          }
        }
      }
    } else if (typeof window.GetElqCustomerGUID === 'function') {
      return window.GetElqCustomerGUID();
    }
    return null;
  };

  private setUTKValue = (guid: string | number): void => setCookie(this.UTK_COOKIE_NAME, guid);

  private getUTKValue = (): string | null => getCookie(this.UTK_COOKIE_NAME);
}

export default EloquaService;
