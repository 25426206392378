/* eslint-disable no-new */
import hubMenuInit from '../hub_nav/hub_nav';
import PrivacyBannerComponent from './privacy_banner/privacy_banner';

const bodyTopInit = () => {
  new PrivacyBannerComponent();
  hubMenuInit();
};

export default bodyTopInit;
