import { isTableBreakpoint } from '../../../common/js_helpers/dom_helpers';
import hubEvents from '../../../common/hub_events/hub_events';

/* Class Names */
const DISABLE_SCROLL_CLASS_NAME = 'no-scroll';
const MOBILE_MENU_ACTIVE_CLASS_NAME = 'is-active';
const HOVER_ACTIVE_CLASS_NAME = 'is-hovered';
const SUBMENU_ACTIVE_CLASS_NAME = 'uf-nav-is-open';
const SUBMENU_LINK_CLASS_NAME = 'uf-dropdown-child';

/* Selectors */
const CONTAINER_SELECTOR = '.container';
const SUBMENU_TOGGLE_SELECTOR = '.navbar-dropdown-toggle';
const SUBMENU_ACTIVE_SELECTOR = `.${SUBMENU_ACTIVE_CLASS_NAME}`;
const SUBMENU_LINK_SELECTOR = `.${SUBMENU_LINK_CLASS_NAME}`;

/* Elements */
let parentNavElement: HTMLElement;
let mobileMenuToggleElement: HTMLButtonElement;
let navbarElement: HTMLElement;
let hoverableElements: HTMLElement[];
let focusableMenuElements: HTMLElement[];

/**
 * @remarks
 * In mobile mode, determines if Mobile Menu is open
 */
const isMobileMenuOpen = (): boolean =>
  mobileMenuToggleElement.classList.contains(MOBILE_MENU_ACTIVE_CLASS_NAME);

const focusFirstMenuElement = (): void => {
  if (focusableMenuElements.length) {
    const firstFocusableElement = focusableMenuElements[0] as HTMLElement;
    firstFocusableElement.focus();
  }
};

/**
 * @remarks
 * Set aria-expanded state on an element
 *
 * @param element
 * @param expandedValue
 */
const updateAriaExpanded = (element: Element, expandedValue: string): void =>
  element.setAttribute('aria-expanded', expandedValue);

/**
 * @remarks
 * Collapse all Submenus that are expanded.
 */
const collapseAllSubmenus = (): void => {
  const activeSubmenuElements = [
    ...navbarElement.querySelectorAll(SUBMENU_ACTIVE_SELECTOR),
  ] as HTMLElement[];

  activeSubmenuElements.forEach((activeSubmenuElement: HTMLElement) => {
    const submenuToggleElement = activeSubmenuElement.querySelector(SUBMENU_TOGGLE_SELECTOR);
    if (!submenuToggleElement) {
      return;
    }

    activeSubmenuElement.classList.remove(SUBMENU_ACTIVE_CLASS_NAME);
    updateAriaExpanded(submenuToggleElement, 'false');
  });
};

/**
 * @remarks
 * Opens the mobile menu by setting the associated class and aria-expanded attributes.
 * Focus the Mobile Menu `x` button after opening.
 */
const showMobileMenu = (): void => {
  if (!mobileMenuToggleElement || !parentNavElement || !navbarElement) {
    return;
  }

  mobileMenuToggleElement.setAttribute('aria-expanded', 'true');
  mobileMenuToggleElement.classList.add(MOBILE_MENU_ACTIVE_CLASS_NAME);
  navbarElement.classList.add(MOBILE_MENU_ACTIVE_CLASS_NAME);
  parentNavElement.classList.add(MOBILE_MENU_ACTIVE_CLASS_NAME);
  document.body.classList.add(DISABLE_SCROLL_CLASS_NAME);

  mobileMenuToggleElement.focus();
};

/**
 * @remarks
 * Closes the mobile menu by setting the associated class and aria-expanded attributes.
 */
const hideMobileMenu = (): void => {
  if (!mobileMenuToggleElement || !parentNavElement || !navbarElement) {
    return;
  }

  mobileMenuToggleElement.setAttribute('aria-expanded', 'false');
  mobileMenuToggleElement.classList.remove(MOBILE_MENU_ACTIVE_CLASS_NAME);
  navbarElement.classList.remove(MOBILE_MENU_ACTIVE_CLASS_NAME);
  parentNavElement.classList.remove(MOBILE_MENU_ACTIVE_CLASS_NAME);
  document.body.classList.remove(DISABLE_SCROLL_CLASS_NAME);

  collapseAllSubmenus();
};

/**
 * @remarks
 * Toggle open/close of the mobile menu.
 */
const toggleMobileMenu = (): void => {
  if (isMobileMenuOpen()) {
    hideMobileMenu();
    mobileMenuToggleElement.focus();
  } else {
    showMobileMenu();
    focusFirstMenuElement();
  }
};

/**
 * @remarks
 * (Desktop mode only) When Esc key pressed, check if activeElement is a Submenu Item.
 * If it is, close the Submenu and focus on the Submenu Toggle Button.
 */
const checkSubmenuEscKey = (): void => {
  if (!document.activeElement || !document.activeElement.parentElement) {
    return;
  }

  const isFocusOnSubmenuLink = document.activeElement.classList.contains(SUBMENU_LINK_CLASS_NAME);
  if (isFocusOnSubmenuLink) {
    const parentMenuElement = document.activeElement.closest(
      SUBMENU_ACTIVE_SELECTOR,
    ) as HTMLElement;

    collapseAllSubmenus();

    if (parentMenuElement) {
      const submenuToggleElement = parentMenuElement.querySelector(
        SUBMENU_TOGGLE_SELECTOR,
      ) as HTMLButtonElement;
      if (submenuToggleElement) {
        submenuToggleElement.focus();
      }
    }
  }
};

/**
 * @remarks
 * When Esc key is clicked, check if we need to hide Mobile Menu or collapse Submenus.
 * If Mobile Menu is open, hide the Mobile Menu.
 *
 * @param event
 */
const handleEscMenuChange = (event: KeyboardEvent): void => {
  if (event.key && ['Escape', 'Esc'].indexOf(event.key) !== -1) {
    if (isTableBreakpoint()) {
      if (isMobileMenuOpen()) {
        hideMobileMenu();
        mobileMenuToggleElement.focus();
      }
    } else {
      checkSubmenuEscKey();
    }
  }
};

/**
 * @remarks
 * (Desktop mode only) When focus changes, check if the activeElement is still a Submenu
 * Link Item. If not, collapse all expanded Submenus.
 */
const checkSubmenuFocus = (): void => {
  if (!isTableBreakpoint()) {
    if (!document.activeElement || !document.activeElement.parentElement) {
      return;
    }

    const isFocusOnSubmenuLink = document.activeElement.classList.contains(SUBMENU_LINK_CLASS_NAME);
    if (!isFocusOnSubmenuLink) {
      collapseAllSubmenus();
    }
  }
};

/**
 * @remarks
 * Handle expand/collapse of Submenus when its control toggle is clicked, and set
 * focus on the first link item in the Submenu.
 */
const bindSubmenuToggleEvents = (): void => {
  const submenuToggleElements = [
    ...navbarElement.querySelectorAll(SUBMENU_TOGGLE_SELECTOR),
  ] as HTMLElement[];

  submenuToggleElements.forEach((toggleElement: Element) => {
    toggleElement.addEventListener('click', () => {
      const parentElement = toggleElement.parentNode as Element;
      if (!parentElement) {
        return;
      }

      const firstSubmenuLink = parentElement.querySelectorAll(
        SUBMENU_LINK_SELECTOR,
      )[0] as HTMLElement;

      // when Click and Mouseover compete in Desktop mode, yield to Mouseover
      const yieldMouseoverControl =
        parentElement.classList.contains(HOVER_ACTIVE_CLASS_NAME) && !isMobileMenuOpen();
      if (!yieldMouseoverControl) {
        const isSubmenuCollapsed = !parentElement.classList.contains(SUBMENU_ACTIVE_CLASS_NAME);

        if (isSubmenuCollapsed) {
          parentElement.classList.add(SUBMENU_ACTIVE_CLASS_NAME);
          updateAriaExpanded(toggleElement, 'true');
          firstSubmenuLink.focus();
        } else {
          parentElement.classList.remove(SUBMENU_ACTIVE_CLASS_NAME);
          updateAriaExpanded(toggleElement, 'false');
        }
      } else {
        parentElement.classList.remove(SUBMENU_ACTIVE_CLASS_NAME);
      }
    });
  });
};

/**
 * @remarks
 * (Desktop mode only) When Parent Menu Item has mouse enter/leave, if there is a
 * Submenu, expand/collapse that Submenu.
 */
const bindMenuHoverEvents = (): void => {
  hoverableElements.forEach((parentElement) => {
    const submenuToggleElement = parentElement.querySelector(SUBMENU_TOGGLE_SELECTOR);
    if (!submenuToggleElement) {
      return;
    }

    parentElement.addEventListener('mouseenter', () => {
      if (!isMobileMenuOpen()) {
        parentElement.classList.add(HOVER_ACTIVE_CLASS_NAME);
        updateAriaExpanded(submenuToggleElement, 'true');
      }
    });

    parentElement.addEventListener('mouseleave', () => {
      if (!isMobileMenuOpen()) {
        parentElement.classList.remove(HOVER_ACTIVE_CLASS_NAME);

        if (!parentElement.classList.contains(SUBMENU_ACTIVE_CLASS_NAME)) {
          updateAriaExpanded(submenuToggleElement, 'false');
        }
      }
    });
  });
};

/**
 * @remarks
 * Add listeners to determine when Submenus need to be collapsed when user focuses
 * outside of an active/expanded Submenu.
 */
const bindSubmenuCollapseEvents = (): void => {
  window.addEventListener('click', checkSubmenuFocus);
  window.addEventListener('keyup', (event: KeyboardEvent) => {
    handleEscMenuChange(event);
    checkSubmenuFocus();
  });
};

/**
 * @remarks
 * Listen to resize event, and if browser is no longer in mobile screen size, and
 * the Mobile Menu is open, let's hide the Mobile Menu, and set the focus on the
 * first focusable menu item.
 */
const windowResizeReset = (): void => {
  hubEvents.subscribe('resize', () => {
    if (!isTableBreakpoint() && isMobileMenuOpen()) {
      hideMobileMenu();
      focusFirstMenuElement();
    }
  });
};

/**
 * @remarks
 * Check if the user has focused outside of the menu elements.
 *
 * @param event
 */
const isFocusOutsideMenu = (event: Event): boolean => {
  const focusNotWithinMenu = !parentNavElement.contains(event.target as Element);
  const focusMenuContainer = event.target === parentNavElement.querySelector(CONTAINER_SELECTOR);
  return focusNotWithinMenu || focusMenuContainer;
};

/**
 * @remarks
 * Close the Mobile Menu if user focused-out of the Mobile Menu. When Mobile Menu
 * closes, focus on the Mobile Menu Toggle button.
 *
 * @param event
 */
const checkMobileMenuClose = (event: Event): void => {
  if (isMobileMenuOpen() && isFocusOutsideMenu(event)) {
    hideMobileMenu();
    mobileMenuToggleElement.focus();
  }
};

/**
 * @remarks
 * Binds event listeners when document is clicked or focused to determine if Mobile
 * Menu needs to be closed.
 */
const bindMobileMenuCloseEvents = (): void => {
  if (!parentNavElement) {
    return;
  }

  const containerElement = parentNavElement.querySelector(CONTAINER_SELECTOR);
  if (!containerElement) {
    return;
  }

  window.addEventListener('focusin', checkMobileMenuClose);
  containerElement.addEventListener('click', checkMobileMenuClose);
};

/**
 * @remarks
 * Find all elements required for the navbar menu functionality. If the page
 * does not have a menu or is missing elements, initialize will be cancelled.
 *
 * @return boolean:  Found all required elements?
 */
const setBindings = (): boolean => {
  parentNavElement = document.getElementById('uf-top-nav-container') as HTMLElement;

  let missingRequiredElements = !parentNavElement;
  if (missingRequiredElements) {
    return false;
  }

  mobileMenuToggleElement = parentNavElement.querySelector('.navbar-burger') as HTMLButtonElement;
  navbarElement = parentNavElement.querySelector('.navbar-menu') as HTMLElement;

  missingRequiredElements = !mobileMenuToggleElement || !navbarElement;
  if (missingRequiredElements) {
    return false;
  }

  hoverableElements = [...navbarElement.querySelectorAll('.is-hoverable')] as HTMLElement[];
  focusableMenuElements = [...navbarElement.querySelectorAll('.is-focusable')] as HTMLElement[];

  return true;
};

/**
 * @remarks
 * Initializes the main menu functions and event listeners.
 */
const initMenubar = (): void => {
  const pageDoesNotHaveNavbar = !setBindings();
  if (pageDoesNotHaveNavbar) {
    return;
  }

  mobileMenuToggleElement.addEventListener('click', toggleMobileMenu);
  bindMobileMenuCloseEvents();
  bindMenuHoverEvents();
  windowResizeReset();
  bindSubmenuToggleEvents();
  bindSubmenuCollapseEvents();
};

export default initMenubar;
