/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { getNumber, getTruthy, htmlStringToElement } from '../../../common/js_helpers/dom_helpers';
import { getPage, setPage } from './stream_navigation_history';
import apiCaller from '../../../common/api_caller';
import get from 'lodash.get';
import hubEvents from '../../../common/hub_events/hub_events';
import setFocusToElement from '../see_more_button/see_more_button';
import TileArea from '../tiles/tile_area';

interface LazyLoaderElements {
  parent: HTMLElement;
  tileList: HTMLElement;
  loadingSpinnerTile: HTMLElement | null;
  seeMoreButton: HTMLButtonElement;
}

interface LazyLoaderState {
  type: string | null; // one of: 'collection', 'recent', 'author', null
  targetId: string | null; // one of: author id, stream id, or "recent"
  page: number;
  loadLimit: number;
  hasMoreItems: boolean;
  lazyLoad: boolean;
  listView: boolean;
  isBusy: boolean;
  excludeCTAs: boolean;
  streamSectionsBetaFlag: boolean;
  totalItemCount: number;
}

class LazyLoaderComponent {
  private readonly BUSY_CLASS_NAME: string = 'uf-is-busy';

  private readonly LIST_VIEW_CLASS_NAME: string = 'uf-list-view';

  private readonly GRID_MODE_THRESHOLD_MULTIPLIER: number = 1.5;

  private readonly LIST_MODE_THRESHOLD_MULTIPLIER: number = 3;

  private PROXIMITY_THRESHOLD_HEIGHT: number = 0;

  private readonly selectors: { [key: string]: string } = {
    loadingSpinnerTile: '#uf-loading-spinner-tile-wrapper',
    parentId: 'uf-lazy-loader',
    seeMoreButton: '#uf-lazy-loader-load-more',
    streamSections: 'stream-section-container',
    tile: '.uf-tile-wrapper:not(.uf-highlight-item)',
    tileList: '#uf-tile-container',
  };

  private dom!: LazyLoaderElements;

  private tileArea!: TileArea;

  private state: LazyLoaderState = {
    excludeCTAs: false,
    hasMoreItems: true,
    isBusy: false,
    lazyLoad: false,
    listView: false,
    loadLimit: 20,
    page: 1,
    streamSectionsBetaFlag: false,
    targetId: null,
    totalItemCount: 0,
    type: null,
  };

  public constructor() {
    if (this.setBindings()) {
      this.init();
    }
  }

  private setBindings = (): boolean => {
    const parent = document.getElementById(this.selectors.parentId) as HTMLElement;
    if (!parent) return false;

    this.dom = {
      loadingSpinnerTile: parent.querySelector(this.selectors.loadingSpinnerTile) as HTMLElement,
      parent,
      seeMoreButton: parent.querySelector(this.selectors.seeMoreButton) as HTMLButtonElement,
      tileList: parent.querySelector(this.selectors.tileList) as HTMLElement,
    };

    if (!this.dom.tileList) {
      return false;
    }

    return true;
  };

  public init(): void {
    this.initState();
    this.bindEvents();
  }

  private initState = (): void => {
    const data = this.dom.parent.dataset || {};
    const type = data.type !== undefined ? data.type : this.state.type;
    const targetId = data.targetId !== undefined ? data.targetId : this.state.targetId;
    const loadLimit = getNumber(data.loadLimit, this.state.loadLimit);
    const hasMoreItems = getTruthy(data.hasMoreItems, this.state.hasMoreItems);
    const lazyLoad = getTruthy(data.lazyLoad, this.state.lazyLoad);
    const listView = getTruthy(data.listView, this.state.listView);
    const excludeCTAs = getTruthy(data.excludeCtas, this.state.excludeCTAs);
    const streamSectionsBetaFlag = getTruthy(
      data.streamSectionsBetaFlag,
      this.state.streamSectionsBetaFlag,
    );
    const totalItemCount = getNumber(data.totalItemCount, this.state.totalItemCount);
    const page = getPage();

    this.state = {
      ...this.state,
      ...{
        excludeCTAs,
        hasMoreItems,
        lazyLoad,
        listView,
        loadLimit,
        page,
        streamSectionsBetaFlag,
        targetId,
        totalItemCount,
        type,
      },
    };
  };

  private bindEvents = (): void => {
    this.bindTileEvents();

    if (this.dom.seeMoreButton) {
      this.dom.seeMoreButton.addEventListener('click', () => this.loadMoreTiles());
    }

    if (this.state.lazyLoad) {
      this.calculateThresholdHeight();
      this.enableInfiniteScroll();
    }

    hubEvents.subscribe('unload', () => setPage(this.state.page));
  };

  private bindTileEvents = (): void => {
    this.tileArea = new TileArea(this.dom.tileList);
    this.tileArea.initializeTiles();
  };

  /**
   * While scrolling down the page, threshold is the number of pixels before the
   * bottom of the Tiles list becomes visible at the viewport-bottom. We should
   * start loading more tiles before reaching the bottom of the Tiles list
   * (around when viewport-bottom clears the 2nd-last row of tiles).
   */
  private calculateThresholdHeight = (): void => {
    const tile = this.dom.tileList.querySelector(this.selectors.tile) as HTMLElement;

    if (tile) {
      const tileStyle = window.getComputedStyle(tile);
      const tileHeight = parseFloat(tileStyle.getPropertyValue('height'));
      const tileMarginBottom = parseFloat(tileStyle.getPropertyValue('margin-bottom'));
      const thresholdHeightMultiplier = this.state.listView
        ? this.LIST_MODE_THRESHOLD_MULTIPLIER
        : this.GRID_MODE_THRESHOLD_MULTIPLIER;
      this.PROXIMITY_THRESHOLD_HEIGHT = (tileHeight + tileMarginBottom) * thresholdHeightMultiplier;
    }
  };

  private enableInfiniteScroll = (): void => hubEvents.subscribe('scroll', this.infiniteScroll);

  private disableInfiniteScroll = (): void => hubEvents.unsubscribe('scroll', this.infiniteScroll);

  /**
   * When browser window is scrolled past the bottom threshold, an API request to
   * load more tiles will be triggered.
   */
  private infiniteScroll = (event: Event) => {
    const { detail } = event as CustomEvent;
    const bottomThresholdReached = detail
      ? this.isBottomReachedInEmbedFrame(detail)
      : this.isBottomReached();

    if (bottomThresholdReached) {
      this.loadMoreTiles(true);
    }
  };

  private currentItemTilesCount = (): number =>
    this.dom.tileList.querySelectorAll('.uf-item-tile').length;

  /**
   * For Hub in iFrame Embed, we must use the parent window's current scroll position
   * to determine if the browser/device viewport-bottom has passed the bottom
   * threshold of the Tiles list.
   *
   * We want to trigger loading of more tiles right before the bottom of the tiles
   * list scrolls into view. We want the next tiles to be ready before the visitor
   * has scrolled to the bottom of the Tiles list.
   *
   * @param scrollTop: current scroll position in parent window
   * @param offsetTop: position of iFrame top in parent page
   * @param viewportY: viewport height on the browser/device
   */
  private isBottomReachedInEmbedFrame = ({
    scrollTop,
    offsetTop,
    viewportY,
  }: EmbedFrameScrollDetail): boolean => {
    // note: tilesBottom remains constant while appearing in iFrame
    const tilesBottom = this.dom.parent.getBoundingClientRect().bottom;
    const thresholdBottom = offsetTop + tilesBottom - this.PROXIMITY_THRESHOLD_HEIGHT;
    const currentScrollBottom = scrollTop + viewportY;
    return currentScrollBottom >= thresholdBottom;
  };

  /**
   * Uses the distance remaining between the viewport-top and the bottom of the
   * Tiles list to determine if we should trigger loading of more tiles.
   *
   * We want to trigger loading of more tiles right before the bottom of the tiles
   * list scrolls into view. We want the next tiles to be ready before the visitor
   * has scrolled to the bottom of the Tiles list.
   */
  private isBottomReached = (): boolean => {
    // note: tilesBottomOffset decreases as you scroll downward toward end of web page
    const tilesBottomOffset = this.dom.parent.getBoundingClientRect().bottom;
    const viewportY = window.innerHeight;
    const distanceRemaining = tilesBottomOffset - this.PROXIMITY_THRESHOLD_HEIGHT;
    return distanceRemaining < viewportY;
  };

  /**
   * Load More Tiles:
   *
   * - make API request to load the next page of Hub Item Tiles
   * - if limit reached, disable Infinite Scroll / "See More" button
   * - for each new Tile, bind JavaScript as required (eg. Form CTA actions)
   * - append new Tiles to DOM
   * - set tab-focus to first new Tile
   * - publish event: `uberflip.itemsLoaded`
   */
  public async loadMoreTiles(infiniteScroll: boolean = false) {
    // don't send another request if already busy with the last request!!!
    if (this.state.isBusy) {
      return;
    }

    this.setBusyStatus(true);
    const tilesHtml = await this.getTilesHtml(infiniteScroll);
    this.setBusyStatus(false);

    if (tilesHtml !== null) {
      const tileElements: HTMLElement[] = htmlStringToElement(tilesHtml.join(''));
      if (this.state.streamSectionsBetaFlag) {
        // trigger renderSectionTiles(tile) only when both marketing stream and stream section beta flag on satisfied.
        this.renderSectionTiles(tileElements);
      } else {
        // if one condition is missing, trigger the older way of item loading
        this.renderNewTiles(tileElements);
      }
      this.focusFirstNewTile(tileElements);
      this.dispatchEvent();
    }

    this.state.hasMoreItems =
      this.currentItemTilesCount() < this.state.totalItemCount && tilesHtml !== null;
    this.manageBindings();
  }

  private setBusyStatus(busy: boolean): void {
    this.state.isBusy = busy;

    if (busy) {
      this.dom.parent.classList.add(this.BUSY_CLASS_NAME);
    } else {
      this.dom.parent.classList.remove(this.BUSY_CLASS_NAME);
    }
  }

  private async getTilesHtml(infiniteScroll = false): Promise<string[] | null> {
    const url = `/themes/tiles/${this.state.type}/${this.state.targetId}`;
    const options = {
      params: {
        excludeCTAs: this.state.excludeCTAs,
        format: 'html',
        infiniteScroll,
        limit: this.state.loadLimit,
        page: (this.state.page += 1),
      },
    };
    const response = await apiCaller.get(url, options);
    return get(response, 'data.response', []);
  }

  private manageBindings = (): void => {
    if (!this.state.hasMoreItems) {
      this.disableLoadingSpinnerTile();
      this.disableSeeMore();
      this.disableInfiniteScroll();
      this.dom.parent.setAttribute('data-has-more-items', `${this.state.hasMoreItems}`);
    }
  };

  private disableLoadingSpinnerTile = (): void => {
    if (this.dom.loadingSpinnerTile) {
      this.dom.loadingSpinnerTile.parentNode!.removeChild(this.dom.loadingSpinnerTile);
      this.dom.loadingSpinnerTile = null;
    }
  };

  private disableSeeMore = (): void => {
    if (this.dom.seeMoreButton) {
      this.dom.seeMoreButton.parentNode!.removeChild(this.dom.seeMoreButton);
    }
  };

  private renderNewTiles = (tileElements: HTMLElement[]): void => {
    tileElements
      .filter((tileElement: HTMLElement) => tileElement.classList)
      .forEach((tileElement: HTMLElement) => {
        if (this.state.listView) {
          tileElement.classList.add(this.LIST_VIEW_CLASS_NAME);
        }

        this.tileArea.initializeTile(tileElement);

        if (this.dom.loadingSpinnerTile) {
          this.dom.tileList.insertBefore(tileElement, this.dom.loadingSpinnerTile);
        } else {
          this.dom.tileList.appendChild(tileElement);
        }
      });
  };

  private renderSectionTiles = (sectionElements: HTMLElement[]): void => {
    let sectionIndex = this.dom.parent.querySelectorAll(`.${this.selectors.streamSections}`).length;

    sectionElements
      .filter((sectionElement: HTMLElement) => sectionElement.classList)
      .forEach((sectionElement: HTMLElement) => {
        const sectionId = sectionElement.getAttribute('id');
        const section = document.getElementById('' + sectionId);

        // content tiles include item tiles and cta tiles
        const contentTileElements: HTMLElement[] = [
          ...(sectionElement.getElementsByClassName(
            'uf-content-tile',
          ) as HTMLCollectionOf<HTMLElement>),
        ];

        if (section !== null) {
          // when the items' section HAS been rendered
          const parentItemsContainer = section.querySelector('.stream-section-items');
          if (parentItemsContainer) {
            contentTileElements.forEach((itemTileElement) => {
              if (this.state.listView) {
                itemTileElement.classList.add(this.LIST_VIEW_CLASS_NAME);
              }
              this.tileArea.initializeTile(itemTileElement);
              parentItemsContainer.appendChild(itemTileElement);
            });
          }
        } else {
          // when the items' section has NOT been rendered
          contentTileElements.forEach((itemTileElement) => {
            if (this.state.listView) {
              itemTileElement.classList.add(this.LIST_VIEW_CLASS_NAME);
            }
            this.tileArea.initializeTile(itemTileElement);
          });

          const sectionIndexString = `section-index-${sectionIndex}`;
          sectionElement.classList.add(sectionIndexString);
          const sectionIndexLink = sectionElement.querySelector('.section-index-link');
          if (sectionIndexLink) {
            sectionIndexLink.setAttribute('id', sectionIndexString);
          }
          sectionIndex++;

          if (this.dom.loadingSpinnerTile) {
            this.dom.tileList.insertBefore(sectionElement, this.dom.loadingSpinnerTile);
          } else {
            this.dom.tileList.appendChild(sectionElement);
          }
        }
      });
  };

  private focusFirstNewTile = (tileElements: HTMLElement[]): void => {
    if (!this.state.lazyLoad) {
      const topTile: HTMLElement = tileElements[0];
      // if class name .stream-section-container DOES exist, it means there are sections
      if (tileElements[0].classList.contains('stream-section-container')) {
        const itemsContainer = topTile.querySelectorAll('.stream-section-items');
        if (!itemsContainer) {
          return;
        }

        const topItemsContainerId = itemsContainer[0].id;
        const topItemsContainer = document.getElementById('' + topItemsContainerId);

        if (!topItemsContainer) {
          return;
        }
        const itemTiles = topItemsContainer.getElementsByClassName('uf-item-tile');
        setFocusToElement(itemTiles[0] as HTMLElement);
      } else {
        // if class name .stream-section-container does NOT exist, it means there are no sections
        setFocusToElement(tileElements[0] as HTMLElement);
      }
    }
  };

  private dispatchEvent = (): void => {
    hubEvents.publish('itemsLoaded');
  };
}

export default LazyLoaderComponent;
