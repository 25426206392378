/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { sanitizeStringHtml } from '../../../../common/js_helpers/dom_helpers';
import RecentSearchesComponent from './recent_searches';

interface SearchBoxSelectors {
  navBarById: string;
  navBarMenu: string;
  search: string;
  openSearchBtn: string;
  closeSearchBtn: string;
  searchForm: string;
  queryInputField: string;
}

export interface SearchBoxElements {
  closeSearchBtn: HTMLButtonElement;
  navBar: HTMLElement;
  navBarMenu: HTMLElement | null;
  openSearchBtn: HTMLButtonElement;
  search: HTMLElement;
  searchForm: HTMLFormElement;
  queryInputField: HTMLInputElement;
}

class SearchComponent {
  private EXPANDED_CLASS_NAME: string = 'uf-search-is-expanded';

  private selectors: SearchBoxSelectors = {
    closeSearchBtn: '#uf-search-close',
    navBarById: 'uf-top-nav-container',
    navBarMenu: '#uf-navbar-menu',
    openSearchBtn: '#uf-search-open',
    queryInputField: '#uf-search-input',
    search: '#uf-search-container',
    searchForm: '#uf-search-form',
  };

  private recentSearchesComponent?: RecentSearchesComponent;

  private dom!: SearchBoxElements;

  public constructor() {
    if (this.setBindings()) {
      this.recentSearchesComponent = new RecentSearchesComponent(this.dom);
      this.init();
    }
  }

  private setBindings(): boolean {
    const navBar = document.getElementById(this.selectors.navBarById) as HTMLElement;
    if (!navBar) {
      return false;
    }

    const search = navBar.querySelector(this.selectors.search) as HTMLElement;
    if (!search) {
      return false;
    }

    const openSearchBtn = search!.querySelector(this.selectors.openSearchBtn) as HTMLButtonElement;
    const closeSearchBtn = search!.querySelector(
      this.selectors.closeSearchBtn,
    ) as HTMLButtonElement;
    const searchForm = search!.querySelector(this.selectors.searchForm) as HTMLFormElement;
    const queryInputField = search!.querySelector(
      this.selectors.queryInputField,
    ) as HTMLInputElement;

    const missingRequiredElements =
      !openSearchBtn || !closeSearchBtn || !searchForm || !queryInputField;
    if (missingRequiredElements) {
      return false;
    }

    const navBarMenu = navBar.querySelector(this.selectors.navBarMenu);

    this.dom = {
      closeSearchBtn,
      navBar,
      navBarMenu: navBarMenu ? (navBarMenu as HTMLElement) : null,
      openSearchBtn,
      queryInputField,
      search,
      searchForm,
    };

    return true;
  }

  private init(): void {
    this.dom.openSearchBtn!.addEventListener('click', this.openSearchForm);
  }

  private openSearchForm = (): void => {
    this.dom.navBar.classList.add(this.EXPANDED_CLASS_NAME);

    this.dom.queryInputField!.focus();

    this.dom.openSearchBtn!.setAttribute('aria-expanded', 'true');

    this.dom.closeSearchBtn!.addEventListener('click', this.closeSearchForm);
    this.dom.queryInputField!.addEventListener('keydown', this.checkKeyPress);
    this.dom.searchForm!.addEventListener('submit', this.submitSearchForm);
    this.dom.openSearchBtn!.addEventListener('focus', this.closeSearchForm);

    // Add events for when focus leaves search component
    document.addEventListener('click', this.checkCloseByFocusOutside);
    document.addEventListener('focusin', this.checkCloseByFocusOutside);

    if (this.dom.navBarMenu) {
      this.dom.navBarMenu.addEventListener('click', this.closeSearchForm);
    }
  };

  private checkCloseByFocusOutside = (event: Event): void => {
    const eventTarget = event.target as HTMLElement;
    if (!this.dom.search.contains(eventTarget)) {
      this.closeSearchForm();
    }
  };

  private checkKeyPress = (event: Event): void => {
    if (event instanceof KeyboardEvent) {
      const keyEvent = event as KeyboardEvent;
      if (keyEvent.type === 'keydown' && keyEvent.key !== 'Escape' && keyEvent.key !== 'Esc') {
        return;
      }
    }

    this.closeSearchForm();
  };

  private closeSearchForm = (): void => {
    this.dom.navBar.classList.remove(this.EXPANDED_CLASS_NAME);

    this.dom.openSearchBtn!.focus();

    this.dom.openSearchBtn!.setAttribute('aria-expanded', 'false');

    this.dom.closeSearchBtn!.removeEventListener('click', this.closeSearchForm);
    this.dom.queryInputField!.removeEventListener('keydown', this.checkKeyPress);
    this.dom.openSearchBtn!.removeEventListener('focus', this.closeSearchForm);
    this.dom.searchForm!.removeEventListener('submit', this.submitSearchForm);

    document.removeEventListener('click', this.checkCloseByFocusOutside);
    document.removeEventListener('focusin', this.checkCloseByFocusOutside);

    if (this.dom.navBarMenu) {
      this.dom.navBarMenu!.removeEventListener('click', this.closeSearchForm);
    }
  };

  private submitSearchForm = (event: Event): void => {
    // sanitize any input to prevent XSS, before passing to component
    const searchInputValue = sanitizeStringHtml(this.dom.queryInputField!.value.trim());

    if (searchInputValue === '') {
      event.preventDefault();
      return;
    }

    this.recentSearchesComponent!.add(searchInputValue);
  };
}

export { SearchComponent };
