import {
  UF_EXCLUDE_QUERY_PARAMS,
  UF_URL_PERSISTED_QUERY_PARAMS,
  UFCC_QUERY_PARAM_REGEX,
} from '../uf_constants';

export interface QueryParams {
  [key: string]: string | null;
}

export function getQueryParams(): QueryParams {
  const queryParams: QueryParams = {};
  const keyValuePairs = window.location.search.substring(1).split('&');

  keyValuePairs.forEach((keyValuePair: string) => {
    if (keyValuePair === '') return;

    const [encodedKey, encodedValue] = keyValuePair.split('=');
    const key = decodeURIComponent(encodedKey);
    if (key === '') return;

    const value = encodedValue ? decodeURIComponent(encodedValue.replace(/\+/g, ' ')) : null;
    queryParams[key] = value || null;
  });

  return queryParams;
}

export const filterParams = (
  queryParams: QueryParams,
  test: (key: string) => boolean,
): QueryParams =>
  Object.keys(queryParams)
    .filter(test)
    .reduce((cleanQueryParams: QueryParams, key: string) => {
      // eslint-disable-next-line no-param-reassign
      cleanQueryParams[key] = queryParams[key];
      return cleanQueryParams;
    }, {});

const removeExcludedParams = (queryParams: QueryParams): QueryParams =>
  filterParams(
    queryParams,
    (key: string) =>
      !UFCC_QUERY_PARAM_REGEX.test(key) && UF_EXCLUDE_QUERY_PARAMS.indexOf(key) === -1,
  );

export const pickUrlPersistedParams = (): QueryParams =>
  filterParams(
    getQueryParams(),
    (key: string) => UF_URL_PERSISTED_QUERY_PARAMS.indexOf(key) !== -1,
  );

export const constructQueryString = (queryParams: QueryParams): string =>
  Object.keys(queryParams)
    .map((key: string) => {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(queryParams[key] || '');
      return `${encodedKey}=${encodedValue}`;
    })
    .join('&');

const updateUrlQuery = (queryParams: QueryParams): void => {
  const { protocol, host, pathname, hash } = window.location;
  const rootUrl = `${protocol}//${host}${pathname}`;
  const queryString = constructQueryString(queryParams);
  const updatedUrl = queryString ? `${rootUrl}?${queryString}${hash}` : `${rootUrl}${hash}`;
  window.history.replaceState(null, '', updatedUrl);
};

export const cleanUrlQueryString = (): void => {
  const queryParams = getQueryParams();
  const filteredQueryParams = removeExcludedParams(queryParams);
  updateUrlQuery(filteredQueryParams);
};

export const setQueryParam = (key: string, value: string): void => {
  const queryParams = getQueryParams();
  queryParams[key] = value;
  updateUrlQuery(queryParams);
};
