/* eslint-disable @typescript-eslint/no-non-null-assertion */
import hubEvents from '../../../../common/hub_events/hub_events';

interface LinkCtaSelectors {
  link: string;
}

interface LinkCtaElements {
  link?: HTMLAnchorElement;
}

class LinkCtaTile {
  private IS_HOOKED_CLASS_NAME = 'uf-cta-initialized';

  private readonly selectors: LinkCtaSelectors = {
    link: '.uf-link-cta-tile-link',
  };

  private readonly cta!: HTMLElement;

  private dom: LinkCtaElements = {};

  public constructor(cta: HTMLElement) {
    this.cta = cta;

    if (this.setBindings()) {
      this.init();
    }
  }

  private setBindings(): boolean {
    if (!this.cta || this.isHooked()) {
      return false;
    }

    this.dom.link = this.cta.querySelector(this.selectors.link) as HTMLAnchorElement;
    return !!this.dom.link;
  }

  private init() {
    this.dom.link!.addEventListener('click', this.broadcastCtaClickedEvent);
    this.markHooked();
  }

  private broadcastCtaClickedEvent = (): void => {
    hubEvents.publish('ctaLinkClick', {
      detail: { caller: this.cta },
    });
  };

  private isHooked = (): boolean => this.cta.classList.contains(this.IS_HOOKED_CLASS_NAME);

  private markHooked = (): void => this.cta.classList.add(this.IS_HOOKED_CLASS_NAME);
}

export default LinkCtaTile;
