/* eslint-disable @typescript-eslint/no-explicit-any */
class LocalStorageHelper {
  private readonly storageKey: string;

  public constructor(storageKey: string) {
    this.storageKey = storageKey;
  }

  public get(): { [key: string]: any } {
    const dataString = localStorage.getItem(this.storageKey);
    return dataString ? JSON.parse(dataString) : null;
  }

  public set(value: any): void {
    const dataString = JSON.stringify(value || {});
    localStorage.setItem(this.storageKey, dataString);
  }
}

export default LocalStorageHelper;
