import { getQueryParams } from '../../../../../common/js_helpers/query_string_helpers';
import CtaQueryParamsStorage from '../../../../../common/hub_tracking/cta_query_params_storage';

class FormCtaField {
  private readonly HIDE_CONDITIONAL_CLASS_NAME = 'uf-hide-conditional';

  private readonly container: HTMLElement;

  private readonly ctaService: string;

  public readonly input: HTMLInputElement | HTMLSelectElement | null;

  public readonly id: number;

  private readonly type: string | null;

  public readonly mapping: string | null;

  private readonly queryStringParamName: string | null;

  public readonly isConditional: boolean;

  private isActive: boolean = true;

  public readonly isCheckbox: boolean;

  private readonly isRequired: boolean;

  private readonly defaultValue: string | number | null;

  public constructor(container: HTMLElement, ctaService: string) {
    this.container = container;
    this.ctaService = ctaService;

    this.id = this.getId();

    this.input = this.getFieldElement();

    this.type = this.getType();
    this.mapping = this.getMapping();
    this.queryStringParamName = this.isQueryStringMapEnabled() ? this.getQueryParamName() : null;

    this.isConditional = this.getConditional();
    this.isCheckbox = this.type === 'checkbox';
    this.isRequired = !!(this.input && this.input.required);

    this.defaultValue = this.getValue();
  }

  private getId = (): number => Number(this.container.getAttribute('data-field-id'));

  private getFieldElement = (): HTMLInputElement | HTMLSelectElement | null => {
    const fieldElement = this.container.querySelector('.uf-preview-field') as HTMLElement;
    if (!fieldElement) return null;

    switch (fieldElement.tagName) {
      case 'INPUT':
        return fieldElement as HTMLInputElement;

      case 'SELECT':
        return fieldElement as HTMLSelectElement;

      default:
        return null;
    }
  };

  private getType(): string {
    if (this.input && this.input.getAttribute('type')) {
      return this.input.getAttribute('type') || 'text';
    }

    if (this.container.getElementsByTagName('select').length) {
      return 'select';
    }

    if (this.container.getElementsByClassName('uf-cta-label-only').length) {
      return 'label';
    }

    throw new Error(`Unable to get field type for field ${this.id}`);
  }

  private getMapping = (): string | null =>
    this.input ? this.input.getAttribute('data-mapping') : null;

  private isQueryStringMapEnabled = (): boolean =>
    !!(
      this.type === 'hidden' &&
      this.input &&
      Number(this.input.getAttribute('data-allow-query-string')) &&
      this.input.getAttribute('data-query-string-param-name')
    );

  private getQueryParamName = (): string | null =>
    (this.input && this.input.getAttribute('data-query-string-param-name')) || null;

  private getConditional = (): boolean =>
    this.container.getAttribute('data-conditional') === 'true';

  private clearConditionalValue = (): void => {
    if (!this.input) return;

    switch (this.type) {
      case 'checkbox': {
        const inputElement = this.input as HTMLInputElement;
        inputElement.checked = inputElement.defaultChecked;
        break;
      }
      case 'select': {
        const selectElement = this.input as HTMLSelectElement;
        selectElement.selectedIndex = this.getResetSelectIndex(selectElement);
        break;
      }
      default:
        this.input.value = (this.defaultValue || '').toString();
    }
  };

  private getResetSelectIndex = (selectElement: HTMLSelectElement): number => {
    const selectOptions = selectElement.options || [];
    const totalOptions = selectOptions.length;

    for (let i = 0; i < totalOptions; i += 1) {
      if (selectOptions[i].defaultSelected) {
        return i;
      }
    }
    return 0;
  };

  // Public:
  // ---

  /**
   * Determine if this field should be completely omitted in the Request Body
   * on Submit of the Form CTA.
   *
   * Reasons to skip a field:
   *  - label only field (no value collected)
   *  - hidden field is empty (where type = 'hidden')
   *  - conditional field is not visible (value is always empty when not visible)
   */
  public shouldSkipSubmit = (): boolean => {
    // We should always submit the email field so that the submission data can be tied to a contact
    if (this.type === 'email') return false;
    if (this.type === 'label') return true;

    const isHiddenFieldEmpty = this.type === 'hidden' && this.getValue() === '';
    const isConditionalFieldInactive = this.isConditional && !this.isActive;

    return isHiddenFieldEmpty || isConditionalFieldInactive;
  };

  public getValue = (): string | number => {
    if (!this.input) return '';

    // If field maps to a query param, use the non-empty param value instead
    if (this.queryStringParamName) {
      const queryParams = getQueryParams();
      const qpValue = (
        queryParams[this.queryStringParamName] ||
        new CtaQueryParamsStorage().get(this.queryStringParamName) ||
        ''
      ).trim();

      if (qpValue) return qpValue;
    }

    // Format checkbox value
    if (this.isCheckbox) {
      const isChecked = (this.input as HTMLInputElement).checked;
      const serviceCheckboxValues: { [key: string]: (string | number)[] } = {
        default: [0, 1],
        Hubspot: ['false', 'true'],
      };
      const checkboxValues =
        serviceCheckboxValues[this.ctaService] || serviceCheckboxValues.default;
      return isChecked ? checkboxValues[1] : checkboxValues[0];
    }

    return this.input.value.trim();
  };

  /**
   * When conditional field meets a condition, make it visible and revert to the
   * `required` state of the input field.
   */
  public showForConditional = (): void => {
    this.isActive = true;
    this.container.classList.remove(this.HIDE_CONDITIONAL_CLASS_NAME);

    if (this.input) {
      this.input.required = this.isRequired;
    }
  };

  /**
   * When conditional field does not meet any condition, hide it and disable the
   * `required` state for the input field.
   *
   * Also, clear any existing value to reset the field.
   */
  public hideForConditional = (): void => {
    this.isActive = false;
    this.container.classList.add(this.HIDE_CONDITIONAL_CLASS_NAME);

    if (this.input) {
      this.input.required = false;
    }

    this.clearConditionalValue();
  };
}

export default FormCtaField;
