import { callParentIFrame, isEmbedFrame } from '../../../../common/js_helpers/embed_frame_helpers';
import hubEvents from '../../../../common/hub_events/hub_events';

interface BackToTopElements {
  skipContent: HTMLElement;
  button: HTMLButtonElement;
}

class BackToTopComponent {
  private readonly VISIBILITY_OFFSET: number = 80;

  private readonly HIDDEN_CLASS_NAME: string = 'uf-invisible';

  private readonly isEmbedFrame: boolean = isEmbedFrame();

  private OFFSET_BOTTOM: number = 0;

  private selectors = {
    buttonById: 'uf-back-to-top',
    skipContentById: 'uf-skip-to-main',
  };

  private dom!: BackToTopElements;

  public constructor() {
    if (this.setBindings()) {
      this.init();
    }
  }

  private setBindings(): boolean {
    this.dom = {
      button: document.getElementById(this.selectors.buttonById) as HTMLButtonElement,
      skipContent: document.getElementById(this.selectors.skipContentById) as HTMLElement,
    };

    const missingRequiredElements = !this.dom.skipContent || !this.dom.button;
    if (missingRequiredElements) {
      return false;
    }

    this.OFFSET_BOTTOM = this.calculateOffsetBottom();
    return true;
  }

  private calculateOffsetBottom = (): number => {
    const buttonStyle = window.getComputedStyle(this.dom.button);
    const buttonHeight = parseFloat(buttonStyle.getPropertyValue('height'));
    const buttonBottom = parseFloat(buttonStyle.getPropertyValue('bottom'));
    return buttonBottom + buttonHeight;
  };

  private init(): void {
    this.dom.button.addEventListener('click', this.handleClick);

    const handleScrollFn = this.isEmbedFrame ? this.handleScrollForEmbedFrame : this.handleScroll;
    hubEvents.subscribe('scroll', handleScrollFn);
  }

  private handleClick = (): void => {
    this.scrollToTop();
    this.dom.skipContent.focus();
  };

  private scrollToTop = (): void => {
    if (this.isEmbedFrame) {
      callParentIFrame(() => window.parentIFrame.scroll('top'));
    } else {
      window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
    }
  };

  private handleScroll = (): void => {
    const isScrolled = window.scrollY >= this.VISIBILITY_OFFSET;
    this.updateVisibility(isScrolled);
  };

  /**
   * When hub is in iFrame Embed, the iFrame expands to 100% height of the content,
   * so scrolling never happens within the frame. So we must observe when scrolling
   * event is triggered in the parent window instead, and we calculate the required
   * values using the parent window dimensions and position.
   *
   * @param event
   */
  private handleScrollForEmbedFrame = (event: Event): void => {
    const { detail } = event as CustomEvent;
    if (!detail) return;

    const { scrollTop, offsetTop, viewportY } = detail;
    const isScrolled = scrollTop > offsetTop + this.VISIBILITY_OFFSET;
    this.updateVisibility(isScrolled);
    this.updatePositionInEmbedFrame(scrollTop, offsetTop, viewportY);
  };

  private updateVisibility = (visible: boolean): void => {
    if (visible) {
      this.show();
    } else {
      this.hide();
    }
  };

  /**
   * As the visitor is scrolling, position the "Back to Top" button within the
   * iFrame to be beside the bottom edge of the viewport.
   *
   * @param scrollTop: current scroll position in parent window
   * @param offsetTop: position of iFrame top in parent page
   * @param viewportY: viewport height on the browser/device
   */
  private updatePositionInEmbedFrame = (
    scrollTop: number,
    offsetTop: number,
    viewportY: number,
  ): void => {
    const hubHeight = window.innerHeight || 0;
    const maxButtonTop = hubHeight - this.OFFSET_BOTTOM;

    const calcButtonTop =
      (scrollTop > offsetTop ? scrollTop - offsetTop : 0) + (viewportY - this.OFFSET_BOTTOM);
    const buttonTop = calcButtonTop < maxButtonTop ? calcButtonTop : maxButtonTop;

    this.dom.button.style.bottom = `auto`;
    this.dom.button.style.top = `${buttonTop}px`;
  };

  private show = (): void => this.dom.button.classList.remove(this.HIDDEN_CLASS_NAME);

  private hide = (): void => this.dom.button.classList.add(this.HIDDEN_CLASS_NAME);
}

export default BackToTopComponent;
