/**
 * Uberflip Hub Events
 *
 * Can be accessed directly using `hubEvents.subscribe('scroll', callbackFunction)`,
 * or natively as `window.addEventListener('uberflip.scroll', callbackFunction)`.
 *
 *
 * Supported Events:
 *
 * 'uberflip.load'
 *   - page finished initialize & is ready
 *
 * 'uberflip.unload'
 *   - visitor is navigating away and page is about to unload
 *
 * 'uberflip.resize'
 *   - browser was resized by visitor (debounced)
 *
 * 'uberflip.scroll'
 *   - browser has been scrolled by visitor (debounced)
 *
 * 'uberflip.ctaLinkClick'
 *   - visitor has clicked a Link CTA's link "button"
 *   - event includes HTMLElement of the Link CTA
 *
 * 'uberflip.scroll'
 *   - browser has been scrolled by user (debounced)
 *
 * 'uberflip.ctaActivate'
 *   - visitor has interacted with a Form CTA, has opened the form fields
 *   - event includes HTMLElement of the Form CTA
 *
 * 'uberflip.ctaFormSubmitSuccess'
 *   - visitor has submitted a Form CTA
 *   - event includes HTMLElement of the Form CTA
 *
 * 'uberflip.itemsLoaded'
 *   - more Hub Item Tiles have been loaded by Infinite Scroll or "See More..." button
 *
 * 'uberflip.recoItemsLoaded'
 *   - Recommended Items have been loaded
 *
 *  'uberflip.flipbookDownload'
 *   - visitor has downloaded a flipbook
 *
 *  'uberflip.ufaOptIn'
 *   - visitor has opted-in to Uberflip Analytics
 *
 *  'uberflip.ufaOptOut'
 *   - visitor has opted-out of Uberflip Analytics
 */

class HubEventsComponent {
  private EVENT_NAME_PREFIX: string = 'uberflip.';

  public constructor() {
    this.bindPolyfill();
  }

  private bindPolyfill = (): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof window.CustomEvent === 'function') {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.CustomEvent = (event, opts) => {
      const params = opts || { bubbles: false, cancelable: false, detail: null };
      const customEvent = document.createEvent('CustomEvent');
      customEvent.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
      return customEvent;
    };
  };

  public subscribe(eventName: string, callbackFunction: EventListener): void {
    window.addEventListener(this.getName(eventName), callbackFunction);
  }

  public unsubscribe(eventName: string, callbackFunction: EventListener): void {
    window.removeEventListener(this.getName(eventName), callbackFunction);
  }

  public publish(eventName: string, eventDictionary?: CustomEventInit): void {
    const event = new CustomEvent(this.getName(eventName), eventDictionary);
    window.dispatchEvent(event);
  }

  private getName(eventName: string): string {
    return this.EVENT_NAME_PREFIX + eventName;
  }
}

const hubEvents = new HubEventsComponent();

export default hubEvents;
