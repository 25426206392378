import get from 'lodash.get';

interface RecoView {
  recoCollectionId?: number | string;
  recotrk?: number | string;
  recoItemId?: number | string;
}

class RecoViewHistory {
  private readonly STORAGE_KEY_NAME = 'uf_last_reco';
  private readonly STORAGE_HIDE_DATE_KEY_NAME = 'uf_reco_hide_date';

  private hideDate = get(
    window,
    'uberflip.recommendationOptions.template_data.hide_publish_date',
    false,
  );

  public handleClick = (event: Event): void => {
    if (event === null) return;
    const tileElement = event.currentTarget as HTMLElement;
    const { id: recoItemId, recotrk, sourceStreamId: recoCollectionId } = tileElement.dataset;
    this.set({ recoCollectionId, recoItemId, recotrk });
    if (this.hideDate) {
      this.setHideDate();
    }
  };

  private set = (recoView: RecoView): void => {
    localStorage.setItem(this.STORAGE_KEY_NAME, JSON.stringify(recoView));
  };

  public get = (): RecoView => {
    const recoView = localStorage.getItem(this.STORAGE_KEY_NAME);
    return recoView ? JSON.parse(recoView) : {};
  };

  public setHideDate = (): void => {
    localStorage.setItem(this.STORAGE_HIDE_DATE_KEY_NAME, 'true');
  };

  public getHideDate = (): boolean => !!localStorage.getItem(this.STORAGE_HIDE_DATE_KEY_NAME);

  public clearHideDate = (): void => {
    localStorage.removeItem(this.STORAGE_HIDE_DATE_KEY_NAME);
  };
}

export default RecoViewHistory;
