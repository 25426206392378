import { constructQueryString, pickUrlPersistedParams } from '../js_helpers/query_string_helpers';
import { getTruthy } from '../js_helpers/dom_helpers';
import { isEmbedFrame } from '../js_helpers/embed_frame_helpers';
import hubEvents from './hub_events';

interface LinkInterceptorOptions {
  isEmbedded: boolean;
  isPreviewMode: boolean;
  linkBreakOut: boolean;
  flipbookBreakOut: boolean;
}

class LinkClickInterceptor {
  private readonly NON_INTERCEPT_TYPES = [undefined, null, '', '0', 'false', 'self'];

  private readonly HOOKED_CLASS_NAME = 'hooked';

  private readonly DYNAMIC_LINK_SELECTOR = `a:not([data-internal]), a[data-internal=""], a[data-internal^="unknown"]`;

  private readonly LINK_SELECTOR = `a[data-internal]:not(.${this.HOOKED_CLASS_NAME})`;

  private readonly FLIPBOOK_BASE_URL = '//read.uberflip.com';

  private readonly BASE_URL = (document.body.dataset.domainWww || '').toLowerCase();

  private options: LinkInterceptorOptions = {
    flipbookBreakOut: false,
    isEmbedded: false,
    isPreviewMode: false,
    linkBreakOut: false,
  };

  public constructor() {
    this.setBindings();
    this.init();
  }

  private setBindings = (): void => {
    const { inPreview } = pickUrlPersistedParams();
    const isEmbedded = isEmbedFrame();
    const isPreviewMode = getTruthy(inPreview);
    const linkBreakOut = getTruthy(document.body.getAttribute('data-hub-linkBreakOut'));
    const flipbookBreakOut = getTruthy(document.body.getAttribute('data-hub-flipbookBreakOut'));

    this.options = {
      flipbookBreakOut,
      isEmbedded,
      isPreviewMode,
      linkBreakOut: isPreviewMode ? false : linkBreakOut,
    };
  };

  private init = (): void => {
    this.markDynamicLinks();
    this.bindInternalLinks();

    // "See More...", Infinite Scroll, and Recommendation items can load async
    hubEvents.subscribe('itemsLoaded', this.bindInternalLinks);
    hubEvents.subscribe('recoItemsLoaded', this.bindInternalLinks);
  };

  /**
   * Check dynamically added links (eg. custom header/footer, WYSIWYG, etc) to determine
   * if they are Hub, Stream, or Item links that should be intercepted.
   *
   * `data-internal` types:
   *  - <no attribute>
   *  - <null> : attribute present, but empty value
   *  - unknown : need to check if internal or external
   *  - unknown_hub_type : hub destination, unknown link type
   */
  private markDynamicLinks = (): void => {
    const linkElements = [
      ...document.querySelectorAll(this.DYNAMIC_LINK_SELECTOR),
    ] as HTMLAnchorElement[];

    linkElements.forEach((element: HTMLAnchorElement) => {
      const testUrl = (element.getAttribute('href') || '').toLowerCase();

      const isHashJumpTo = testUrl.indexOf('#') === 0;
      if (isHashJumpTo) {
        element.setAttribute('data-internal', 'self');
        return;
      }

      const testFlipbookIndex = testUrl.indexOf(this.FLIPBOOK_BASE_URL);
      const isFlipbook = testFlipbookIndex >= 0 && testFlipbookIndex <= 5;
      if (isFlipbook) {
        element.setAttribute('data-internal', 'uberflip');
        return;
      }

      const isInternal = testUrl.indexOf(this.BASE_URL) === 0;
      if (isInternal) {
        element.setAttribute('data-internal', 'standard');
        return;
      }

      element.setAttribute('data-internal', 'false');
      element.setAttribute('rel', 'noopener');
    });
  };

  private bindInternalLinks = (): void => {
    const linkElements = [...document.querySelectorAll(this.LINK_SELECTOR)] as HTMLAnchorElement[];

    linkElements.forEach((linkElement: HTMLAnchorElement) => {
      linkElement.addEventListener('click', this.handleLinkClick);
      linkElement.classList.add(this.HOOKED_CLASS_NAME);
    });
  };

  /**
   * Internal links (hub, stream, item, etc) will be intercepted ...
   *  - link breakout: to open in a target window,
   *  - iFrame Embed: to append query params that we want to persist to the next hub page.
   *
   * Notes:
   *  - currently, only iFrame Embed and Preview query params are persisted on page change.
   *  - currently, we only override the click Event when the Hub is embedded, or Flipbook
   *    Breakout option is Enabled on a Flipbook link; otherwise, the original Event is
   *    executed without interruption.
   *  - when visitor is breaking-out of iFrame (when using Link Breakout option, or by
   *    using [CMD|CTRL|SHIFT] + CLICK), we DO NOT want to persist iFrame Embed options.
   *
   * `data-internal` types:
   *  - false : external link
   *  - self : internal jump-to #hash, do not bind
   *  - standard : hub destination, unknown link type
   *  - home : hub home page
   *  - custom|marketing|targeted : stream page
   *  - authors : author page
   *  - uberflip : flipbook item page
   *  - blogpost|youtube|etc : hub item page
   *  - privacy : privacy page
   *  - locales : hub language link to change locale
   */
  private handleLinkClick = (event: MouseEvent): void | boolean => {
    const element = event.currentTarget as HTMLAnchorElement;
    const internalType = (element.dataset.internal || '').toLowerCase();
    let targetUrl = element.getAttribute('href') || '';
    let targetWindow = null;

    const noIntercept = this.NON_INTERCEPT_TYPES.indexOf(internalType) !== -1;
    if (noIntercept) return;

    const isNewWindowKeyPress = event.ctrlKey || event.shiftKey || event.metaKey;
    if (isNewWindowKeyPress) return;

    const isFrame = this.options.isEmbedded || this.options.isPreviewMode;
    const isFlipbookBreakout = this.options.flipbookBreakOut && internalType === 'uberflip';
    if (!isFrame && !isFlipbookBreakout) return;

    // from here, default link click d will be overridden:
    event.preventDefault();

    if (isFlipbookBreakout) {
      targetWindow = '_blank';
    } else if (this.options.linkBreakOut) {
      targetWindow = '_top';
    } else {
      const persistedParams = pickUrlPersistedParams();

      // iFrame Embeds: for next page, reset scroll of frame (if required)
      if (getTruthy(persistedParams.embedded)) {
        persistedParams.scrollTop = 'top';
      }

      const queryString = constructQueryString(persistedParams);
      targetUrl += queryString ? (targetUrl.match(/\?/g) ? '&' : '?') + queryString : '';
    }

    if (targetWindow) {
      window.open(targetUrl, targetWindow);
    } else {
      window.location.href = targetUrl;
    }

    return false;
  };
}

export default LinkClickInterceptor;
