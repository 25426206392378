/* eslint-disable no-new */
import '../../../common/privacy/privacy';
import './hub_page.scss';
import {
  hubEventLoadDispatch,
  sharedHubEventsInit,
} from '../../../common/hub_events/shared_hub_events';
import bodyTopInit from '../../components/body_top/body_top';
import footerInit from '../../components/footer/footer';
import LazyLoaderComponent from '../../components/lazy_loader/lazy_loader';

const main = () => {
  sharedHubEventsInit();
  bodyTopInit();
  footerInit();
  new LazyLoaderComponent();
  hubEventLoadDispatch();
};

document.addEventListener('DOMContentLoaded', main);
