/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { sanitizeStringHtml } from '../../../../common/js_helpers/dom_helpers';
import { SearchBoxElements } from './search';

interface RecentSearchSelectors {
  container: string;
  list: string;
  button: string;
}

class RecentSearchesComponent {
  private dom!: SearchBoxElements;

  private selectors: RecentSearchSelectors = {
    button: '.uf-recent-search-button',
    container: '#uf-recent-searches',
    list: '#uf-recent-search-list',
  };

  private MAX_RECENT_SEARCH = 5;

  private localStorageKey = 'uf-recent-searches';

  public constructor(searchBoxElements: SearchBoxElements) {
    this.dom = searchBoxElements;
    this.init();
  }

  private init(): void {
    const listElement = this.dom.search.querySelector(this.selectors.list);
    if (!listElement) {
      return;
    }

    const searchHistory = this.getAll();

    if (searchHistory.length <= 0) {
      this.deconstruct();
      return;
    }

    const listItemsHTML = searchHistory
      .map(
        (queryString) =>
          `<li>
            <button class="uf-recent-search-button" aria-label="Search for: ${queryString}" type="button">
              <i class="fas fa-search" aria-hidden="true"></i>
              <span class="uf-recent-search-query">${sanitizeStringHtml(queryString)}</span>
            </button>
          </li>`,
      )
      .join('');

    listElement.innerHTML = listItemsHTML;

    this.bindButtonEvents();
  }

  private getAll = (): string[] => {
    const searchHistory = window.localStorage.getItem(this.localStorageKey);
    return searchHistory ? searchHistory.split(',') : [];
  };

  private deconstruct = (): void => {
    const containerElement = this.dom.search.querySelector(this.selectors.container);
    if (!containerElement) {
      return;
    }

    containerElement.parentNode!.removeChild(containerElement);
  };

  private bindButtonEvents(): void {
    const buttonElements = [...this.dom.search.querySelectorAll(this.selectors.button)];
    buttonElements.forEach((buttonElement: Element) => {
      buttonElement.addEventListener('click', this.search);
    });
  }

  private search = (event: Event): void => {
    const recentSearch = (event.currentTarget as HTMLElement).querySelector(
      '.uf-recent-search-query',
    )!.innerHTML;

    if (!this.dom.queryInputField) {
      return;
    }

    this.add(recentSearch);
    this.dom.queryInputField.value = recentSearch;
    this.dom.searchForm.submit();
  };

  // Public:

  public add(newSearch: string): void {
    const searchHistory = this.getAll();

    const currentIndex = searchHistory.indexOf(newSearch);
    if (currentIndex !== -1) {
      searchHistory.splice(currentIndex, 1);
    }

    if (searchHistory.length >= this.MAX_RECENT_SEARCH) {
      searchHistory.pop();
    }

    searchHistory.unshift(newSearch);
    window.localStorage.setItem(this.localStorageKey, searchHistory.join());
  }
}

export default RecentSearchesComponent;
