/* eslint-disable  no-new */
import { SearchComponent } from './search/search';
import HubSocialSharingComponent from './hub_social_sharing/hub_social_sharing';
import initMenubar from './hub_nav_menubar';
import LocaleSelectorComponent from './locale_selector/locale_selector';

const hubMenuInit = () => {
  initMenubar();
  new SearchComponent();
  new HubSocialSharingComponent();
  new LocaleSelectorComponent();
};

export default hubMenuInit;
