/* eslint-disable no-new */
import EloquaService from './services/eloqua';
import FormCtaActivator from './helpers/form_cta_activate';

class FormCtaTile {
  private IS_HOOKED_CLASS_NAME = 'uf-cta-initialized';

  private readonly cta!: HTMLElement;

  public constructor(cta: HTMLElement) {
    this.cta = cta;
    this.init();
  }

  private init() {
    if (this.isHooked()) {
      return;
    }

    new FormCtaActivator(this.cta);
    new EloquaService();

    this.markHooked();
  }

  private isHooked = (): boolean => this.cta.classList.contains(this.IS_HOOKED_CLASS_NAME);

  private markHooked = (): void => this.cta.classList.add(this.IS_HOOKED_CLASS_NAME);
}

export default FormCtaTile;
