import privacyPreferences from './privacy_preferences';

// Bind to `window.uberflip` so Privacy API can be used by third-party developers:
window.uberflip = window.uberflip || {};

window.uberflip.Privacy = {
  acceptAll: privacyPreferences.acceptAll,
  acceptById: privacyPreferences.acceptById,
  applyChanges: privacyPreferences.applyChanges,
  getAll: privacyPreferences.getAll,
  getByFunctionalityCode: privacyPreferences.getByFunctionalityCode,
  getById: privacyPreferences.getById,
  isFunctionalityEnabled: privacyPreferences.isFunctionalityEnabled,
  rejectAll: privacyPreferences.rejectAll,
  rejectById: privacyPreferences.rejectById,
  saveChanges: privacyPreferences.saveChanges,
};
