/**
 * Sets the tab-focus on the first focusable element within the parent element
 */
const setFocusToElement = (parentElement: Element) => {
  const focusableSelector =
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

  const firstFocusableElement = parentElement.querySelector(focusableSelector) as HTMLElement;

  firstFocusableElement.focus();
};

export default setFocusToElement;
