interface HubSocialSharingElements {
  parent: HTMLElement;
  toggleButton: HTMLButtonElement;
  shareLinks: HTMLAnchorElement[];
}

class HubSocialSharingComponent {
  private readonly SHARE_OPEN_CLASS_NAME = 'is-open';

  private readonly selectors = {
    links: 'a',
    parentById: 'uf-social-share',
    toggleButton: '#uf-share-toggle',
  };

  private dom!: HubSocialSharingElements;

  public constructor() {
    if (this.setBindings()) {
      this.init();
    }
  }

  private setBindings = (): boolean => {
    const parent = document.getElementById(this.selectors.parentById);
    if (!parent) {
      return false;
    }

    const toggleButton = parent.querySelector(this.selectors.toggleButton) as HTMLButtonElement;
    const shareLinks = [...parent.querySelectorAll(this.selectors.links)] as HTMLAnchorElement[];
    if (!toggleButton || !shareLinks) {
      return false;
    }

    this.dom = { parent, shareLinks, toggleButton };

    return true;
  };

  private init = (): void => {
    this.dom.parent.addEventListener('mouseenter', this.open);
    this.dom.parent.addEventListener('mouseout', this.close);
    this.dom.parent.addEventListener('keydown', this.escapeKeyClose);

    this.dom.toggleButton.addEventListener('click', this.toggle);
    this.dom.toggleButton.addEventListener('keydown', this.escapeKeyClose);
  };

  private open = (): void => {
    document.addEventListener('click', this.close);
    document.addEventListener('focusin', this.checkCloseByFocusOutside);
    this.dom.parent.classList.add(this.SHARE_OPEN_CLASS_NAME);
    this.dom.toggleButton.setAttribute('aria-expanded', 'true');
  };

  private close = (): void => {
    document.removeEventListener('click', this.close);
    document.removeEventListener('focusin', this.checkCloseByFocusOutside);
    this.dom.parent.classList.remove(this.SHARE_OPEN_CLASS_NAME);
    this.dom.toggleButton.setAttribute('aria-expanded', 'false');
  };

  private toggle = (event: Event): void => {
    event.stopPropagation();

    const isOpen = this.dom.parent.classList.contains(this.SHARE_OPEN_CLASS_NAME);
    if (isOpen) {
      this.close();
    } else {
      this.open();
      this.focusFirstShareLink();
    }
  };

  private focusFirstShareLink = (): void => {
    if (!this.dom.shareLinks.length) return;
    this.dom.shareLinks[0].focus();
  };

  private escapeKeyClose = (event: Event): void => {
    const keyEvent = event as KeyboardEvent;
    if (keyEvent.key === 'Escape' || keyEvent.key === 'Esc') {
      this.close();
      this.dom.toggleButton.focus();
    }
  };

  // close Social Sharing drop-down when tab-focus leaves the container element
  private checkCloseByFocusOutside = (event: Event): void => {
    const isFocusInsideParent = this.dom.parent.contains(event.target as Element);
    if (!isFocusInsideParent) {
      this.close();
    }
  };
}

export default HubSocialSharingComponent;
