/* eslint no-param-reassign: ["error", { "props": false }] */

/**
 * calculate an image's ratios to determine if it's a portrait image
 *
 * @param imageEl - element of tile thumbnail image
 * @returns boolean - the the tile thunbail a portrait or not
 *
 */
const calculateImageDimensions = (imageEl: HTMLImageElement) =>
  imageEl.naturalHeight >= imageEl.naturalWidth;

/**
 * Based on a flipbook's thumbnail image ratios apply a class of .uf-portrait
 * if the thumbnail is a portrait image
 *
 * @param tile - element of tile
 */
const addPortraitClassToFlipbookTile = (tile: Element) => {
  const tileLink = tile.querySelector('.uf-tile') as HTMLElement;
  const isFlipbook = tileLink.classList.contains('uberflip');
  const alreadyProcessed = tile.classList.contains('uf-portrait');
  if (!isFlipbook || alreadyProcessed) return;

  const tileImgEl = tile.querySelector('.uf-tile-thumbnail img') as HTMLImageElement;

  if (!tileImgEl) return;
  if (tileImgEl.complete) {
    if (calculateImageDimensions(tileImgEl)) {
      tileLink.classList.add('uf-portrait');
    }
  } else {
    tileImgEl.onload = function runOnLoad() {
      if (calculateImageDimensions(tileImgEl)) {
        tileLink.classList.add('uf-portrait');
      }
    };
  }
};

const initItemTile = (tile: HTMLElement) => {
  addPortraitClassToFlipbookTile(tile);
};

export default initItemTile;
