import { getQueryParams, setQueryParam } from '../../../common/js_helpers/query_string_helpers';

/**
 * Called on load of the Lazy Loader.
 * @return {number} - returns the current tile page of the lazy loader based on query string
 */
export const getPage = (): number => {
  const queryParams = getQueryParams() || {};
  if (!queryParams.page) return 1;
  const page = parseInt(queryParams.page, 10);
  return page ? Math.abs(page) : 1;
};

/**
 * Called HubEvents unload
 * sets query parameter of page in the URL's search queries before navigating away
 * @param {number} page - the current tile page of the lazy loader based on query string
 */
export const setPage = (page: number): void => {
  if (page === 1) return;
  setQueryParam('page', page.toString());
};
