/* eslint-disable @typescript-eslint/no-explicit-any */
import { filterParams, getQueryParams, QueryParams } from '../js_helpers/query_string_helpers';
import { UF_URL_PERSISTED_QUERY_PARAMS } from '../uf_constants';
import LocalStorageHelper from '../js_helpers/local_storage_helper';
import privacyPreferences from '../privacy/privacy_preferences';

class CtaQueryParamsStorage {
  private readonly STORAGE_KEY: string = 'uf_cta_query_params';

  private readonly qpStorage: LocalStorageHelper;

  public constructor() {
    this.qpStorage = new LocalStorageHelper(this.STORAGE_KEY);
  }

  // URL has already filtered UF_EXCLUDED_QUERY_PARAMS by this point of execution
  private pickCtaPersistedQueryParams = (): QueryParams =>
    filterParams(
      getQueryParams(),
      (key: string) => UF_URL_PERSISTED_QUERY_PARAMS.indexOf(key) === -1,
    );

  private set = (paramName: string, value: any): void => {
    const storedQueryParams = this.qpStorage.get() || {};
    storedQueryParams[paramName] = value;
    this.qpStorage.set(storedQueryParams);
  };

  // Public:
  // ---

  public storeCurrent = (): void => {
    const isTrackingBlocked = !privacyPreferences.isTrackingAllowed();
    if (isTrackingBlocked) {
      return;
    }

    const ctaPersistedQueryParams = this.pickCtaPersistedQueryParams();

    Object.keys(ctaPersistedQueryParams).forEach((paramName: string) => {
      const formattedParamName = paramName.trim();
      if (formattedParamName) {
        this.set(formattedParamName, ctaPersistedQueryParams[paramName]);
      }
    });
  };

  public get = (paramName: string): { [key: string]: any } => {
    const storedQueryParams = this.qpStorage.get() || {};
    const formattedParamName = paramName.trim();
    return storedQueryParams[formattedParamName];
  };
}

export default CtaQueryParamsStorage;
