const UF_MOBILE_BREAKPOINT = '(max-width: 560px)';

const UF_TABLE_BREAKPOINT = '(max-width: 1336px)';

const UF_RECO_PANEL_REQUIRED_WIDTH = 260;

const UF_EXCLUDE_QUERY_PARAMS = [
  'page',
  'scrollTop',
  'prevCol',
  'prevItm',
  'ts',
  'recotrk',
  'visual',
];

const UF_URL_PERSISTED_QUERY_PARAMS = [
  'inPreview',
  'offsetTop',
  'offsetBottom',
  'embedded',
  'hideBanner',
  'hideHeader',
  'hideFooter',
  'hidePriNav',
  'hideSecNav',
  'linkBreakOut',
];

const UFCC_QUERY_PARAM_REGEX = /ufcc_[a-zA-Z0-9_]*/g;

// Message types for postMessage/addEventListener
const MESSAGE_TYPES = {
  FLIPBOOK_EVENT: 'flipbook.event',
};

export {
  UF_MOBILE_BREAKPOINT,
  UF_TABLE_BREAKPOINT,
  UF_RECO_PANEL_REQUIRED_WIDTH,
  UF_EXCLUDE_QUERY_PARAMS,
  UF_URL_PERSISTED_QUERY_PARAMS,
  UFCC_QUERY_PARAM_REGEX,
  MESSAGE_TYPES,
};
