/* eslint-disable */
/**
 * A wrapper for creating a variable that is read from / written to session storage,
 * containing events for any side effects that may be needed. Used to move us away from
 * ajax based navigation, while preserving state for the user across page changes.
 *
 * @class SessionHelper
 */
class SessionHelper {
  private readonly sessionStorageKey: any;

  constructor(key: string) {
    this.sessionStorageKey = key;
  }

  /**
   * Get the value of a particular key on this session variable,
   * or get the entire sessionHelper object (with no key provided).
   * Triggers "get" Event.
   *
   * @param {String} key
   * @memberof SessionHelper
   */
  public get(key: string) {
    if (key) {
      const data = sessionStorage.getItem(this.sessionStorageKey);
      const obj = data ? JSON.parse(data) : {};
      return obj[key];
    } else {
      const data = sessionStorage.getItem(this.sessionStorageKey);
      return data ? JSON.parse(data) : {};
    }
  }

  /**
   * Set a particular key on this session variable to a
   * provided value. Triggers "set" event.
   *
   * @param {String} key
   * @param {*} value
   * @returns {Object}
   * @memberof SessionHelper
   */
  public set(key: string, value: string | number) {
    const data = sessionStorage.getItem(this.sessionStorageKey);
    const obj = data ? JSON.parse(data) : {};
    obj[key] = value;
    sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(obj));
    return obj;
  }

  /**
   * Delete the contents of a session variable, replacing them with an
   * empty object.
   *
   * @returns {SessionHelper}
   * @memberof SessionHelper
   */
  public clear() {
    sessionStorage.setItem(this.sessionStorageKey, JSON.stringify({}));
  }
}

export default SessionHelper;
