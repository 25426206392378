import FormCtaField from './form_cta_field';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
const numberKeys = (obj: {}) => Object.keys(obj).map(Number);

interface Condition {
  parent_field_id: number;
  value: string;
  operator: string;
}

export interface Conditions {
  [key: number]: Condition[];
}

interface KeyMappedFormField {
  [key: number]: FormCtaField;
}

interface KeyMappedNumberList {
  [key: number]: number[];
}

class FormCtaConditionals {
  private readonly allConditions!: Conditions;

  private readonly formCtaFields!: FormCtaField[];

  private readonly allFieldsById!: KeyMappedFormField;

  private readonly parentSubscribers!: KeyMappedNumberList;

  public constructor(conditions: Conditions, formCtaFields: FormCtaField[]) {
    if (!Object.keys(conditions).length) {
      return;
    }

    this.allConditions = conditions;
    this.formCtaFields = formCtaFields;

    this.allFieldsById = this.getFormCtaFieldsById();
    this.parentSubscribers = this.getParentSubscribers();

    this.bindParentChangeEvents();
    this.initConditionalFieldVisibility();
  }

  /**
   * Returns object where each key is mapped to a Parent Form Field Id, and the
   * value is a list of all the Conditional Child Form Fields ([id, id, ...]) that
   * may be impacted whenever the value of the parent field changes.
   */
  private getParentSubscribers = (): KeyMappedNumberList => {
    const parentSubscribers: KeyMappedNumberList = [];

    numberKeys(this.allConditions).forEach((childFieldId: number) => {
      const fieldConditions = this.allConditions[childFieldId];

      fieldConditions.forEach((condition: Condition) => {
        const parentFieldId = condition.parent_field_id;
        if (!parentSubscribers[parentFieldId]) {
          parentSubscribers[parentFieldId] = [];
        }

        const childNotInList = parentSubscribers[parentFieldId].indexOf(childFieldId) === -1;
        if (childNotInList) {
          parentSubscribers[parentFieldId].push(childFieldId);
        }
      });
    });

    return parentSubscribers;
  };

  private getFormCtaFieldsById = (): KeyMappedFormField => {
    const fieldsById: KeyMappedFormField = [];

    this.formCtaFields.forEach((formCtaField: FormCtaField) => {
      fieldsById[formCtaField.id] = formCtaField;
    });

    return fieldsById;
  };

  /**
   * Bind change event on all parent form fields.
   */
  private bindParentChangeEvents = (): void => {
    numberKeys(this.parentSubscribers).forEach((parentFieldId: number) =>
      this.bindParentFieldValueChange(parentFieldId),
    );
  };

  /**
   * Binds change event to the Parent Form Fields, so whenever the value is
   * changed, the subscribing Conditional/Child Fields are checked to see
   * if they should be shown or hidden.
   *
   * @param parentFieldId: unique id of a field on which conditional fields rely
   */
  private bindParentFieldValueChange = (parentFieldId: number): void => {
    const formCtaField = this.allFieldsById[parentFieldId];
    if (!formCtaField || !formCtaField.input) {
      return;
    }

    formCtaField.input.addEventListener('change', () => {
      const subscribers: number[] = this.parentSubscribers[parentFieldId];
      subscribers.forEach((childFieldId: number) => this.toggleConditionalField(childFieldId));
    });
  };

  /**
   * Initialize the visible/hidden state of each conditional form field,
   * based on its initial/existing value (could be an existing value from a
   * tracked visitor).
   */
  private initConditionalFieldVisibility = (): void => {
    numberKeys(this.allFieldsById)
      .map((fieldId: number) => this.allFieldsById[fieldId])
      .filter((formCtaField: FormCtaField) => formCtaField.isConditional)
      .forEach((formCtaField: FormCtaField) => this.toggleConditionalField(formCtaField.id));
  };

  /**
   * Show Conditional Field if any of its conditions are true, otherwise hide the
   * field from being fillable.
   *
   * @param childFieldId: unique id of a conditional field that relies on another
   *   field's value
   */
  private toggleConditionalField = (childFieldId: number): void => {
    const formCtaField = this.allFieldsById[childFieldId];
    const childConditions = this.allConditions[childFieldId];
    let showConditionalField = false;

    for (let i = 0; i < childConditions.length; i += 1) {
      const childCondition = childConditions[i];
      const { parent_field_id: parentFieldId, value: requiredValue, operator } = childCondition;

      const parentField = this.allFieldsById[parentFieldId];
      if (parentField) {
        let parentFieldValue = parentField.getValue().toString();

        // (HubSpot variation) map checkbox 'true'/'false' to '1'/'0' for condition compare
        if (parentField.isCheckbox) {
          parentFieldValue = ['true', '1'].indexOf(parentFieldValue) !== -1 ? '1' : '0';
        }

        if (this.isConditionTrue(parentFieldValue, requiredValue, operator)) {
          showConditionalField = true;
          break;
        }
      }
    }

    if (showConditionalField) {
      formCtaField.showForConditional();
    } else {
      formCtaField.hideForConditional();
    }
  };

  private isConditionTrue = (value: string, requiredValue: string, operator: string): boolean =>
    (value === requiredValue && operator === 'EQUAL_TO') ||
    (value !== requiredValue && operator === 'NOT_EQUAL_TO');
}

export default FormCtaConditionals;
