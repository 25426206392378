/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { htmlStringToElement } from '../../../../../common/js_helpers/dom_helpers';
import apiCaller from '../../../../../common/api_caller';
import FormCtaConditionals, { Conditions } from './form_cta_conditionals';
import FormCtaField from './form_cta_field';
import FormCtaSubmitter from './form_cta_submitter';
import get from 'lodash.get';
import hubEvents from '../../../../../common/hub_events/hub_events';

interface FormCtaSelectors {
  loading: string;
  error: string;
  success: string;
  landing: {
    panel: string;
    activate: string;
  };
  form: {
    panel: string;
    fields: string;
    field: string;
    input: string;
    deactivate: string;
  };
}

interface FormCtaElements {
  loading: HTMLElement;
  error: HTMLElement;
  success: HTMLElement;
  landing: {
    panel: HTMLElement;
    activate: HTMLButtonElement;
  };
  form: {
    panel: HTMLFormElement;
    fields: HTMLElement;
    deactivate: HTMLButtonElement;
  };
}

interface FormCtaFieldResponse {
  html: string[];
  conditions: Conditions;
}

class FormCtaActivator {
  private readonly ACTIVATED_CLASS_NAME = 'uf-cta-is-activated';

  private readonly HIDE_CLASS_NAME = 'uf-hidden';

  private readonly TRANSITION_TIME = 250;

  private readonly LOADING_PANEL_DELAY = 300;

  private readonly KEY_EVENT = 'keydown';

  private readonly selectors: FormCtaSelectors = {
    error: '.uf-cta-error-panel',
    form: {
      deactivate: '.uf-cta-deactivate-button',
      field: '.uf-cta-field',
      fields: '.uf-cta-api-fields',
      input: '.uf-preview-field',
      panel: '.uf-cta-activated-panel',
    },
    landing: {
      activate: '.uf-cta-activate-button',
      panel: '.uf-cta-landing-panel',
    },
    loading: '.uf-cta-loading-panel',
    success: '.uf-cta-success-panel',
  };

  private readonly cta: HTMLElement;

  private ctaId!: string;

  private ctaService!: string;

  private neverHideShowSuccess: boolean = false;

  private dom!: FormCtaElements;

  private loadingTimer: ReturnType<typeof setTimeout> | null = null;

  private loadFieldsCompleted: boolean = false;

  private isActivated: boolean = false;

  private readonly formCtaFields: FormCtaField[] = [];

  // Constructor
  // ---

  public constructor(cta: HTMLElement) {
    this.cta = cta;

    if (this.setBindings()) {
      this.init();
    }
  }

  // Private
  // ---

  private setBindings(): boolean {
    if (!this.cta) {
      return false;
    }

    this.ctaId = this.cta.getAttribute('data-id') as string;
    this.ctaService = this.cta.getAttribute('data-integration') as string;
    this.neverHideShowSuccess = this.cta.getAttribute('data-show-success') === 'true';

    const missingRequiredAttributes = !this.ctaId || !this.ctaService;
    if (missingRequiredAttributes) {
      return false;
    }

    const loading = this.cta.querySelector(this.selectors.loading) as HTMLElement;
    const error = this.cta.querySelector(this.selectors.error) as HTMLElement;
    const success = this.cta.querySelector(this.selectors.success) as HTMLElement;
    const landingPanel = this.cta.querySelector(this.selectors.landing.panel) as HTMLElement;
    const formPanel = this.cta.querySelector(this.selectors.form.panel) as HTMLFormElement;

    let missingRequiredElements = !loading || !error || !success || !landingPanel || !formPanel;
    if (missingRequiredElements) {
      return false;
    }

    const activate = landingPanel.querySelector(
      this.selectors.landing.activate,
    ) as HTMLButtonElement;
    const deactivate = formPanel.querySelector(this.selectors.form.deactivate) as HTMLButtonElement;
    const fields = formPanel.querySelector(this.selectors.form.fields) as HTMLElement;

    missingRequiredElements = !activate || !deactivate || !fields;
    if (missingRequiredElements) {
      return false;
    }

    this.dom = {
      error,
      form: {
        deactivate,
        fields,
        panel: formPanel,
      },
      landing: {
        activate,
        panel: landingPanel,
      },
      loading,
      success,
    };

    return true;
  }

  private init(): void {
    if (this.neverHideShowSuccess) {
      this.isActivated = true;
      this.loadFieldsCompleted = true;
      this.setActivated();
      this.showSuccessPanel();
      return;
    }

    this.dom.landing.activate!.addEventListener('click', this.activate);
    this.dom.landing.activate!.addEventListener('mousedown', this.activate);
    this.dom.landing.activate!.addEventListener(this.KEY_EVENT, this.activate);
    this.dom.form.deactivate!.addEventListener('click', this.deactivate);

    new FormCtaSubmitter(this.cta, this.dom.form.panel, this.formCtaFields, this);
  }

  private activate = (event: Event): void => {
    if (event.type === this.KEY_EVENT && !this.isActivationKeypress(event as KeyboardEvent)) {
      return;
    }

    if (this.isActivated) {
      return;
    }

    this.isActivated = true;

    if (this.loadFieldsCompleted) {
      this.showFormPanel();
      return;
    }

    this.loadFormFields()
      .then((data: FormCtaFieldResponse) => {
        this.appendFieldsToDom(data.html);
        this.getFormCtaFields();
        this.bindFieldConditions(data.conditions);
        this.broadcastCtaActivatedEvent();
        this.hideLoadingPanel();
        this.showFormPanel();
      })
      .catch((error: Error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.hideLoadingPanel();
        this.showErrorPanel();
      });
  };

  private isActivationKeypress = (event: KeyboardEvent): boolean =>
    event.type === this.KEY_EVENT && [' ', 'Enter'].indexOf(event.key) !== -1;

  private loadFormFields = async (): Promise<FormCtaFieldResponse> => {
    this.showLoadingPanel();

    const params = { format: 'html' };
    const response = await apiCaller.get(`/themes/ctaFormFields/${this.ctaId}`, { params });

    this.loadFieldsCompleted = true;

    return get(response, 'data.response', []);
  };

  private appendFieldsToDom = (html: string[]): void => {
    const fields = html.map((fieldString: string) => htmlStringToElement(fieldString)[0]);
    fields.forEach((field: HTMLElement) => this.dom.form.fields!.appendChild(field));
  };

  private getFormCtaFields = (): void => {
    const selector = this.selectors.form.field;
    const fieldElements = [
      ...(this.dom.form.fields.querySelectorAll(selector) as NodeListOf<HTMLElement>),
    ];
    fieldElements.forEach((fieldElement: HTMLElement) => {
      this.formCtaFields.push(new FormCtaField(fieldElement, this.ctaService));
    });
  };

  private bindFieldConditions = (conditions: Conditions): void => {
    new FormCtaConditionals(conditions, this.formCtaFields);
  };

  private broadcastCtaActivatedEvent = (): void => {
    hubEvents.publish('ctaActivate', {
      detail: { caller: this.cta },
    });
  };

  private showFormPanel = (): void => {
    // un-hide form panel
    this.dom.form.panel!.classList.remove(this.HIDE_CLASS_NAME);

    // set active class on cta parent (which will move form panel into visibility)
    setTimeout(() => this.setActivated(), 0);

    // hide landing panel when transition completes
    setTimeout(() => {
      this.focusFirstFormField();
      this.dom.landing.panel!.classList.add(this.HIDE_CLASS_NAME);
    }, this.TRANSITION_TIME);
  };

  private focusFirstFormField = (): void => {
    const selector = this.selectors.form.input;
    const firstField = this.dom.form.fields!.querySelector(
      `${selector}:not(.uf-cta-hidden)`,
    ) as HTMLInputElement;

    if (firstField) {
      firstField.focus();
    }
  };

  private deactivate = (): void => {
    if (!this.isActivated) {
      return;
    }

    this.isActivated = false;

    // un-hide landing panel
    this.dom.landing.panel!.classList.remove(this.HIDE_CLASS_NAME);

    // scroll form panel back to top before hiding it
    this.dom.form.panel!.scrollTop = 0;

    // remove active class on cta parent (which will move landing panel into visibility)
    setTimeout(() => this.setDeactivated(), 0);

    // hide form when transition completes
    setTimeout(() => {
      this.dom.form.panel!.classList.add(this.HIDE_CLASS_NAME);
      this.dom.landing.activate!.focus();
    }, this.TRANSITION_TIME);
  };

  private setDeactivated = (): void => this.cta.classList.remove(this.ACTIVATED_CLASS_NAME);

  private setActivated = (): void => this.cta.classList.add(this.ACTIVATED_CLASS_NAME);

  // Public
  // ---

  public showLoadingPanel = (): void => {
    this.cta.setAttribute('aria-busy', 'true');

    // only show loading spinner if API request takes too long
    this.loadingTimer = setTimeout(() => {
      this.dom.loading!.classList.remove(this.HIDE_CLASS_NAME);
      this.loadingTimer = null;
    }, this.LOADING_PANEL_DELAY);
  };

  public hideLoadingPanel = (): void => {
    this.cta.setAttribute('aria-busy', 'false');

    // cancel loading spinner if it was not needed
    if (this.loadingTimer) {
      clearTimeout(this.loadingTimer);
      this.loadingTimer = null;
    }
    this.dom.loading!.classList.add(this.HIDE_CLASS_NAME);
  };

  public showErrorPanel = (): void => {
    this.isActivated = false;
    this.dom.landing.panel!.classList.add(this.HIDE_CLASS_NAME);
    this.dom.form.panel!.classList.add(this.HIDE_CLASS_NAME);
    this.dom.error!.classList.remove(this.HIDE_CLASS_NAME);
  };

  public showSuccessPanel = (): void => {
    this.dom.form.panel!.classList.add(this.HIDE_CLASS_NAME);
    this.dom.success!.classList.remove(this.HIDE_CLASS_NAME);
  };
}

export default FormCtaActivator;
