import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

class ApiCaller {
  private readonly config: AxiosRequestConfig = {
    // IE11 bug fix - do not cache requests
    headers: {
      'Cache-Control': 'no-cache, no-store',
      Pragma: 'no-cache',
    },
    timeout: 5000,
  };

  private readonly axiosInstance: AxiosInstance;

  public constructor() {
    this.bindBaseUrl();
    this.axiosInstance = axios.create(this.config);
  }

  private bindBaseUrl = (): void => {
    const baseUrl = document.body.getAttribute('data-domain-www');
    if (baseUrl) {
      this.config.baseURL = baseUrl;
    }
  };

  public getInstance = (): AxiosInstance => this.axiosInstance;
}

const apiCaller = new ApiCaller().getInstance();

export default apiCaller;
