/* eslint-disable no-new */
import { cleanUrlQueryString } from '../js_helpers/query_string_helpers';
import CtaQueryParamsStorage from '../hub_tracking/cta_query_params_storage';
import debounce from 'lodash.debounce';
import EmbedFrameMessenger from './embed_frame_messenger';
import handlePageUnload from './page_unload_event';
import hubEvents from './hub_events';
import LinkClickInterceptor from './link_click_interceptor';

export const sharedHubEventsInit = () => {
  const debounceScroll = debounce(() => hubEvents.publish('scroll'));
  const debounceResize = debounce(() => hubEvents.publish('resize'));

  window.addEventListener('scroll', debounceScroll);
  window.addEventListener('resize', debounceResize);
  window.addEventListener('beforeunload', () => {
    handlePageUnload();
    hubEvents.publish('unload');
    // necessary to ensure unload scripts run on Internet Explorer/Edge
    return undefined;
  });

  hubEvents.subscribe('load', () => {
    new EmbedFrameMessenger();
    new LinkClickInterceptor();
    cleanUrlQueryString();
    new CtaQueryParamsStorage().storeCurrent();
  });
};

export const hubEventLoadDispatch = () => {
  hubEvents.publish('load');
};
