import SessionHelper from '../js_helpers/session_helper';

const handlePageUnload = (): void => {
  const previousState = new SessionHelper('previousState');
  const { itemId, streamId, authorId } = document.body.dataset;
  previousState.set('itemId', itemId || '');
  previousState.set('collectionId', streamId || authorId || 'recent');
};

export default handlePageUnload;
