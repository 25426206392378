import privacyPreferences from '../../../../common/privacy/privacy_preferences';

class PrivacyBannerComponent {
  private readonly VISIBILITY_CLASS_NAME = 'uf-slide-up';

  private readonly selectors: PrivacyBannerSelectors = {
    acceptAllBtn: '.uf-privacy-banner-accept',
    bannerById: 'uf-privacy-banner',
    dismissBtn: '.uf-privacy-banner-close',
    manageLink: '.uf-privacy-banner-manage',
  };

  private dom!: PrivacyBannerElements;

  private readonly privacyPreferences = privacyPreferences;

  public constructor() {
    if (this.setBindings()) {
      this.bindEvents();
      this.show();
    }
  }

  private setBindings = (): boolean => {
    const banner = document.getElementById(this.selectors.bannerById) as HTMLElement;
    if (!banner) return false;

    const dismissBtn = banner.querySelector(this.selectors.dismissBtn) as HTMLButtonElement;
    if (!dismissBtn) return false;

    // The privacy page where a user can manage groups and the accept button are both optional
    // and may not be rendered to the page - we will check for them before binding the events
    const acceptAllBtn = banner.querySelector(this.selectors.acceptAllBtn) as HTMLButtonElement;
    const manageLink = banner.querySelector(this.selectors.manageLink) as HTMLAnchorElement;

    this.dom = { acceptAllBtn, banner, dismissBtn, manageLink };
    return true;
  };

  private bindEvents = () => {
    this.dom.dismissBtn.addEventListener('click', this.dismiss);

    // Check the optional elements and bind events if they are present
    if (this.dom.manageLink) {
      this.dom.manageLink.addEventListener('click', this.dismiss);
    }

    if (this.dom.acceptAllBtn) {
      this.dom.acceptAllBtn.addEventListener('click', this.accept);
    }
  };

  public dismiss = (): void => {
    this.privacyPreferences.dismissBanner();
    this.destruct();
  };

  private accept = (): void => {
    this.privacyPreferences.acceptAll();
    this.privacyPreferences.applyChanges();
    this.dismiss();
  };

  private show = (): void => this.dom.banner.classList.add(this.VISIBILITY_CLASS_NAME);

  private destruct = (): void => {
    if (this.dom.banner.parentNode) {
      this.dom.banner.parentNode.removeChild(this.dom.banner);
    }
  };
}

export default PrivacyBannerComponent;
